import api from '../index';
import { TDensity } from '../../../models/feeding';
import service from 'api/services/feeding.service';
import buildFormDataQuery from '../../utils/buildFormDataQuery';
import { TResult } from '../../../models/all';

export const feedingDensityApi = api.injectEndpoints({
  endpoints: builder => ({
    getFeedingDensity: builder.query<Array<TDensity>, void>({
      query: () => '/sprav_eat_col_get/',
      transformResponse: res => service.densityGet(res.data.sprav_eat_col),
      providesTags: ['FeedingDensity'],
    }),

    addFeedingDensity: builder.mutation<
      TResult<{ sprav_eat_col: number }>,
      any
    >({
      query: buildFormDataQuery('/sprav_eat_col_add/'),
      transformResponse: res => res.data,
      invalidatesTags: ['FeedingDensity'],
    }),

    editFeedingDensity: builder.mutation<
      TResult<{ sprav_eat_col: number }>,
      any
    >({
      query: buildFormDataQuery('/sprav_eat_col_edit/'),
      transformResponse: res => res.data,
      invalidatesTags: ['FeedingDensity'],
    }),

    // editFeedingDensity: builder.mutation<
    //   TResult<{ sprav_eat_col: unknown }>,
    //   Array<TNutrientsEditParams>
    // >({
    //   query: params => {
    //     const preparedParams = {
    //       id_item: params[0].id_item,
    //       ...params
    //         .map(({ key, value }) => ({ [key]: value }))
    //         .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
    //     };
    //     return buildFormDataQuery('/sprav_eat_col_edit/')(preparedParams);
    //   },
    //   transformResponse: res => res.data,
    //   invalidatesTags: ['FeedingDensity'],
    // }),

    deleteFeedingDensity: builder.mutation<
      TResult<{ sprav_eat_col: number }>,
      { id_item: string | number }
    >({
      query: buildFormDataQuery('/sprav_eat_col_del/'),
      transformResponse: res => res.data,
      invalidatesTags: ['FeedingDensity'],
    }),
  }),
});

export const {
  useGetFeedingDensityQuery,
  useAddFeedingDensityMutation,
  useEditFeedingDensityMutation,
  useDeleteFeedingDensityMutation,
} = feedingDensityApi;
