import api from '../index';
import { TFeedingWeekValue } from '../../../models/feeding';
import service from 'api/services/feeding.service';
import buildFormDataQuery from 'store/utils/buildFormDataQuery';
import { TResult } from 'models/all';

type TFeedingWeekRes = {
  values: TFeedingWeekValue[];
  items: number[];
};
export const feedingApi = api.injectEndpoints({
  endpoints: builder => ({
    getFeedingWeek: builder.query<TFeedingWeekRes, void>({
      query: () => '/settings_get/',
      transformResponse: res => service.createValue(res.data.settings),
      providesTags: ['Feeding'],
    }),
    editFeedingWeek: builder.mutation({
      query: buildFormDataQuery('/settings_edit/'),
      invalidatesTags: ['Feeding'],
    }),
    deleteFeedingWeek: builder.mutation<
      TResult<{ settings: number }>,
      { id_item: string | number }
    >({
      query: buildFormDataQuery('/settings_del/'),
      transformResponse: res => res.data,
      invalidatesTags: ['Feeding'],
    }),
  }),
});

export const {
  useGetFeedingWeekQuery,
  useEditFeedingWeekMutation,
  useDeleteFeedingWeekMutation,
} = feedingApi;
