import styled from 'styled-components';

export const TooltipWrapper = styled.div`
  position: relative;
  display: block;
`;

export const TooltipText = styled.div`
  visibility: hidden;
  min-width: 130px;
  background: #fff;
  color: #2e3e5c;
  text-align: center;
  border-radius: 6px;
  padding: 13px;
  position: absolute;
  z-index: 300;
  left: 65px;
  top: -9px;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
  filter: drop-shadow(0px 23px 40px rgba(120, 146, 130, 0.1))
    drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.06));
`;

export const TooltipArrow = styled.div`
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 1px;
  background: #fff;
  transform: rotate(45deg);
  top: 18px;
  left: -4.5px;
`;
