import api from './index';
import service from '../../api/services/analyses.servise';
import buildFormDataQuery from '../utils/buildFormDataQuery';
import { TResult } from '../../models/all';
import { TAnalysesItem, TAnalysesItemAdd } from '../../models/analyses';

type TGetAnalyses = {
  start_item?: number;
  count_item?: number;
};

export const analysesApi = api.injectEndpoints({
  endpoints: builder => ({
    getAnalyses: builder.query<
      { data: TAnalysesItem[]; total: number },
      TGetAnalyses
    >({
      query: params => ({
        url: '/sprav_anal_params_get/',
        params,
      }),
      transformResponse: res => ({
        data: service.getValue(res.data.sprav_anal_params),
        total: res.data.total,
      }),
      providesTags: ['Analyses'],
    }),

    addAnalyses: builder.mutation<
      TResult<{ Analyses: number }>,
      TAnalysesItemAdd
    >({
      query: buildFormDataQuery('/sprav_anal_params_add/'),
      transformResponse: res => res.data,
      invalidatesTags: ['Analyses'],
    }),

    editAnalyses: builder.mutation<null, TAnalysesItem & { id_item: number }>({
      query: buildFormDataQuery('/sprav_anal_params_edit/'),
      transformResponse: res => res.data,
      invalidatesTags: ['Analyses'],
    }),

    deleteAnalyses: builder.mutation<
      TResult<{ Analyses: number }>,
      { id_item: string | number }
    >({
      query: buildFormDataQuery('/sprav_anal_params_del/'),
      transformResponse: res => res.data,
      invalidatesTags: ['Analyses'],
    }),
  }),
});

export const {
  useGetAnalysesQuery,
  useAddAnalysesMutation,
  useEditAnalysesMutation,
  useDeleteAnalysesMutation,
} = analysesApi;
