import { createSlice } from '@reduxjs/toolkit';
import { RootStore } from '../types';
import { techCardsApi } from '../services/techCards';
import { TechCardsItem } from '../../models/techCards';

interface TechCardsState {
  items: TechCardsItem[] | undefined;
}

const initialState: TechCardsState = {
  items: undefined,
};

const { getTechCards } = techCardsApi.endpoints;

const slice = createSlice({
  name: 'techCards',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder.addMatcher(
      getTechCards.matchFulfilled,
      (state: TechCardsState, { payload }) => {
        state.items = payload.items;
      },
    ),
});
export const selectTechCards = (state: RootStore): TechCardsItem[] =>
  state.techCards.items;

export const selectTechCardsById =
  (id: number | string) =>
  (state: RootStore): TechCardsItem | undefined => {
    const { items } = state.techCards;
    if (items) return items?.find((tc: TechCardsItem) => tc.id === id);
  };

export const { reducer: techCardsReducer, actions: techCardsActions } = slice;
