import api from './index';
import service from '../../api/services/questions.service';
import buildFormDataQuery from '../utils/buildFormDataQuery';
import { TResult } from '../../models/all';
import { TQuestionGetResult } from '../../models/questions';

type TQItemAdd = {
  name: string;
  anals: string;
  dep: number;
  id_disease: string;
  ball?: string;
};

export const questionsApi = api.injectEndpoints({
  endpoints: builder => ({
    getQuestions: builder.query<TQuestionGetResult, void>({
      query: () => '/sprav_questions_get/',
      transformResponse: res => ({
        items: service.getValue(res.data.sprav_questions),
        total: res.data.total,
      }),
      providesTags: ['Questions'],
    }),

    addQuestion: builder.mutation<
      TResult<{ sprav_questions: number }>,
      TQItemAdd
    >({
      query: buildFormDataQuery('/sprav_questions_add/'),
      transformResponse: res => res.data,
      invalidatesTags: ['Questions'],
    }),

    editQuestion: builder.mutation({
      query: buildFormDataQuery('/sprav_questions_edit/'),
      invalidatesTags: ['Questions'],
    }),

    deleteQuestion: builder.mutation<
      TResult<{ sprav_questions: number }>,
      { id_item: string | number }
    >({
      query: buildFormDataQuery('/sprav_questions_del/'),
      transformResponse: res => res.data,
      invalidatesTags: ['Questions'],
    }),
  }),
});

export const {
  useGetQuestionsQuery,
  useAddQuestionMutation,
  useEditQuestionMutation,
  useDeleteQuestionMutation,
} = questionsApi;
