import styled, { keyframes } from 'styled-components/macro';
import { ScButtonWrap } from 'styles';
import { theme } from 'theme';

const showBg = keyframes`
    form {
        background-color: rgba(18, 26, 45, 0);
    }
    to{
        background-color: rgba(18, 26, 45, 0.2);

    }
`;

const showModal = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

export const ScModalContainer = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  background-color: rgba(18, 26, 45, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${showBg} 0.3s 0.01s ease forwards;
  box-sizing: border-box;
`;

export const ScModalBody = styled.div`
  ${ScButtonWrap} {
    width: 100%;
    justify-content: space-between;
    button {
      flex: 0 0 calc(50% - 8px);
      max-width: calc(50% - 8px);
      justify-content: center;
      margin: 0;
      height: 40px;
      font-weight: 600;
      font-size: 14px;
      line-height: 155%;
      &:first-child {
        font-weight: 500;
      }
    }
  }
`;

export const ScModal = styled.div<{ modalWidth: number }>`
  width: ${({ modalWidth }) => (modalWidth ? `${modalWidth}px` : '610px')};
  max-height: calc(100vh - 20px);
  background-color: ${theme.color.white};
  padding: 24px;
  position: relative;
  opacity: 0;
  animation: ${showModal} 0.3s 0.01s ease forwards;
  border-radius: 20px;
  box-shadow: ${theme.effect.popUpShadow};
  .text {
    text-align: center;
    color: ${theme.color.secondary};
    font-weight: 500;
    font-size: 14px;
    line-height: 155%;
    margin: 0 0 24px;
  }
  .headline {
    display: flex;
    flex-direction: column;
    align-items: center;
    svg {
      margin: 0 0 24px;
    }
    h3 {
      width: 100%;
      font-weight: 600;
      font-size: 18px;
      line-height: 150%;
      text-align: center;
      margin: 0 0 12px;
    }
  }
`;
