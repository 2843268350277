import { createGlobalStyle } from 'styled-components';
import { theme } from 'theme';

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: 'Montserrat';
    src: url('fonts/MontserratRegular.eot');
    src: url('fonts/MontserratRegular.eot?#iefix') format('embedded-opentype'),
    url('fonts/MontserratRegular.woff2') format('woff2'),
    url('fonts/MontserratRegular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url('fonts/MontserratMedium.eot');
    src: url('fonts/MontserratMedium.eot?#iefix') format('embedded-opentype'),
    url('fonts/MontserratMedium.woff2') format('woff2'),
    url('fonts/MontserratMedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url('fonts/MontserratSemiBold.eot');
    src: url('fonts/MontserratSemiBold.eot?#iefix') format('embedded-opentype'),
    url('fonts/MontserratSemiBold.woff2') format('woff2'),
    url('fonts/MontserratSemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
  }

  * {
    font-family: 'Montserrat', sans-serif;
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    color: ${theme.color.primary}

  }

  img {
    max-width: 100%;
    vertical-align: bottom;
  }

  h1, h2, h3, h4, h5 {
    margin: 0;
    padding: 0;
  }

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 120%; 
  }

  h2 {
    font-size: 24px;
    line-height: 140%;
  }
  
  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.02em;
  }

  p {
    margin: 0;
  }

  a {
    color: ${theme.color.accent};
    text-decoration: underline;
    transition: .2s;

    &:hover {
      color: ${theme.color.secondary};
      text-decoration: none;
    }
  }

  .asideHeader {
    width: 32px;
    position: relative;
    max-width: 40px;

    &:before {
      position: absolute;
      content: attr(data-title);
      rotate: -90deg;
      left: 50%;
      top: 50%;
      translate: -50% -50%;
      white-space: nowrap;
    }

    & + td {
      /* position: sticky; */
      left: 42px;
      z-index: 10;
    }
  }

  .aside-no-sticky {
    & + td {
      position: unset;
    }
  }

  table {
    &:not(.whithoutBorder) {
      tr td {
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
      }
    }

    tr {
      //cursor: pointer;
      position: relative;

      td {
        svg {
          position: relative;
          z-index: 2;
        }
      }
    }
  }

  thead tr:before {
    opacity: 0;
  }
`;

export default GlobalStyle;
