import {
  TCategory,
  TFullProductCategories,
  TGetProductCategories,
  TGetProductsParams,
  TProductCategories,
} from 'models/productGuide';

const findElement = (
  data: TProductCategories[],
  id: number,
): TProductCategories[] => {
  return data
    .filter(el => Number(el.id_parent) === id)
    .map(el => {
      return {
        id: Number(el.id),
        id_parent: Number(el.id_parent),
        name: el.name,
        max_part: el.max_part,
        min_part: el.min_part,
      };
    });
};

const getFullCategory = (
  data: TGetProductCategories,
): TFullProductCategories[] => {
  const subs = data.deps.filter(el => Number(el.id_parent) !== 0);

  const arr = data.deps
    .filter(el => Number(el.id_parent) === 0)
    .map(el => {
      return {
        name: el.name,
        id: Number(el.id),
        max_part: el.max_part,
        min_part: el.min_part,
        sub: findElement(subs, Number(el.id)),
      };
    });

  return arr;
};

const getFullCategoryInline = (data: TCategory[]): TFullProductCategories[] => {
  const subs = data.filter(el => Number(el.id_parent) !== 0);

  const arr = data
    .filter(el => Number(el.id_parent) === 0)
    .map(el => {
      return {
        name: el.name,
        id: Number(el.id),
        max_part: el.max_part,
        min_part: el.min_part,
        sub: findElement(subs, Number(el.id)),
      };
    });

  return arr;
};

const getParams = ({
  start,
  count,
  filter,
}: TGetProductsParams): Record<string, unknown> => {
  const getFilter = () => {
    if (!filter) return [];
    const id_dep: { key: string; value: string[] } = {
      key: 'id_dep',
      value: [],
    };
    const is_del: { key: string; value: string | null } = {
      key: 'is_del',
      value: null,
    };
    const id_type: { key: string; value: string[] } = {
      key: 'id_type',
      value: [],
    };
    const group: { key: string; value: string | null } = {
      key: 'group',
      value: null,
    };
    const is_carb: { key: string; value: string | null } = {
      key: 'is_carb',
      value: null,
    };
    const is_simple: { key: string; value: string | null } = {
      key: 'is_simple',
      value: null,
    };
    const is_protein: { key: string; value: string | null } = {
      key: 'is_protein',
      value: null,
    };
    const is_animal: { key: string; value: string | null } = {
      key: 'is_animal',
      value: null,
    };
    const is_vegetable: { key: string; value: string | null } = {
      key: 'is_vegetable',
      value: null,
    };
    const is_fat: { key: string; value: string | null } = {
      key: 'is_fat',
      value: null,
    };
    const label: { key: string; value: string | null } = {
      key: 'label',
      value: null,
    };
    const id: { key: string; value: number[] | null } = {
      key: 'id',
      value: null,
    };

    filter.forEach(({ param, value }) => {
      switch (param) {
        case 'id_dep':
          return id_dep.value.push(String(value));
        case 'id_type':
          return id_type.value.push(String(value));
        case 'group':
          return (group.value = String(value));
        case 'is_carb':
          return (is_carb.value = String(value));
        case 'is_simple':
          return (is_simple.value = String(value));
        case 'is_protein':
          return (is_protein.value = String(value));
        case 'is_animal':
          return (is_animal.value = String(value));
        case 'is_vegetable':
          return (is_vegetable.value = String(value));
        case 'is_fat':
          return (is_fat.value = String(value));
        case 'label':
          return (label.value = String(value));
        case 'id':
          return (id.value = filter.map(({ value }) => Number(value)));
        case 'is_del':
          return (is_del.value = String(value));
        default:
          return;
      }
    });

    return {
      id_dep: id_dep.value,
      id_type: id_type.value,
      group: group.value,
      is_carb: is_carb.value,
      is_simple: is_simple.value,
      is_protein: is_protein.value,
      is_animal: is_animal.value,
      is_vegetable: is_vegetable.value,
      is_fat: is_fat.value,
      label: label.value,
      is_del: is_del.value,
      id: id.value,
    };
  };
  const defParams = !count
    ? { start_item: start }
    : { start_item: start, count_item: count };

  const params = {
    ...defParams,
    ...getFilter(),
  };

  return params;
};

export default { getFullCategory, getFullCategoryInline, getParams };
