import {
  TDensity,
  TFeedingWeekValue,
  TFinalElement,
  TVal,
  TWeekValue,
  IntervalItem,
  TDietItem,
  TFeedingProteinsItem,
} from 'models/feeding';

const createItem = (val: Record<string, any>): TFinalElement[] => {
  const initValues = Object.values(val);
  return (
    Object.keys(val)
      // .filter(el => Number(el) !== 0) // to filter if item id === 0
      .map((el, index) => ({
        item_id: Number(el) === 0 ? 1 : Number(el),
        min: Number(initValues[index].min),
        max: Number(initValues[index].max),
      }))
  );
};

const getTotal = (data: TFinalElement[], key: 'min' | 'max'): number => {
  const currentArr = data.map(el => el[key]);
  return currentArr.reduce((acc, curr) => acc + curr, 0);
};

const createValue = (
  init: TWeekValue<string>[],
): { values: TFeedingWeekValue[]; items: number[] } => {
  const clearJson: TWeekValue<TVal>[] = init.map(el => ({
    id: el.id,
    val: JSON.parse(el.val),
  }));

  const distructObject = clearJson.map(({ id, val }) => ({
    id: id,
    c0: {
      dop: createItem(Object.values(val.c0)[0]),
      basic: createItem(Object.values(val.c0)[1]),
      dopMinTotal: getTotal(createItem(Object.values(val.c0)[0]), 'min'),
      dopMaxTotal: getTotal(createItem(Object.values(val.c0)[0]), 'max'),
      basicMinTotal: getTotal(createItem(Object.values(val.c0)[1]), 'min'),
      basicMaxTotal: getTotal(createItem(Object.values(val.c0)[1]), 'max'),
    },
    c1: {
      dop: createItem(Object.values(val.c1)[0]),
      basic: createItem(Object.values(val.c1)[1]),
      dopMinTotal: getTotal(createItem(Object.values(val.c1)[0]), 'min'),
      dopMaxTotal: getTotal(createItem(Object.values(val.c1)[0]), 'max'),
      basicMinTotal: getTotal(createItem(Object.values(val.c1)[1]), 'min'),
      basicMaxTotal: getTotal(createItem(Object.values(val.c1)[1]), 'max'),
    },
    p0: {
      dop: createItem(Object.values(val.p0)[0]),
      basic: createItem(Object.values(val.p0)[1]),
    },
    p1: {
      dop: createItem(Object.values(val.p1)[0]),
      basic: createItem(Object.values(val.p1)[1]),
    },
    f0: {
      dop: createItem(Object.values(val.f0)[0]),
      basic: createItem(Object.values(val.f0)[1]),
      dopMinTotal: getTotal(createItem(Object.values(val.f0)[0]), 'min'),
      dopMaxTotal: getTotal(createItem(Object.values(val.f0)[0]), 'max'),
      basicMinTotal: getTotal(createItem(Object.values(val.f0)[1]), 'min'),
      basicMaxTotal: getTotal(createItem(Object.values(val.f0)[1]), 'max'),
    },
    f1: {
      dop: createItem(Object.values(val.f1)[0]),
      basic: createItem(Object.values(val.f1)[1]),
      dopMinTotal: getTotal(createItem(Object.values(val.f1)[0]), 'min'),
      dopMaxTotal: getTotal(createItem(Object.values(val.f1)[0]), 'max'),
      basicMinTotal: getTotal(createItem(Object.values(val.f1)[1]), 'min'),
      basicMaxTotal: getTotal(createItem(Object.values(val.f1)[1]), 'max'),
    },
  }));

  const onlyUnique = (value: number, index: number, self: number[]) => {
    return self.indexOf(value) === index;
  };

  const itemsId = distructObject
    .map(({ c0, c1, p0, p1, f0, f1 }) => [
      ...c0.dop.map(({ item_id }) => item_id),
      ...c0.basic.map(({ item_id }) => item_id),
      ...c1.dop.map(({ item_id }) => item_id),
      ...c1.basic.map(({ item_id }) => item_id),
      ...p0.dop.map(({ item_id }) => item_id),
      ...p1.basic.map(({ item_id }) => item_id),
      ...f0.dop.map(({ item_id }) => item_id),
      ...f1.basic.map(({ item_id }) => item_id),
    ])
    .flat()
    .filter(onlyUnique);

  return { values: distructObject, items: itemsId };
};

const densityGet = (init: TDensity[]): TDensity[] => {
  return init
    .map(({ id, name, col, p1, p2, p3, p4, p5 }) => ({
      id: Number(id),
      name,
      col: Number(col),
      p1: Number(p1),
      p2: Number(p2),
      p3: Number(p3),
      p4: Number(p4),
      p5: Number(p5),
    }))
    .sort((a, b) => a.col - b.col);
};

const intervalGet = (data: IntervalItem[]): IntervalItem[] => {
  return data.map(({ id, name, value }) => ({
    id: Number(id),
    name,
    value: Number(value),
  }));
};

const dietGet = (data: TDietItem[]): TDietItem[] => {
  return data.map(({ id, name, p_carb, p_fat, p_protein }) => ({
    id: Number(id),
    name,
    p_carb: Number(p_carb),
    p_fat: Number(p_fat),
    p_protein: Number(p_protein),
  }));
};

const proteinsGet = (data: TFeedingProteinsItem[]): TFeedingProteinsItem[] => {
  return data.map(({ id, name, p0, p1 }) => ({
    id: Number(id),
    name,
    p0: Number(p0),
    p1: Number(p1),
  }));
};

export default { createValue, densityGet, intervalGet, dietGet, proteinsGet };
