import { TQuestionItem } from 'models/questions';

const getValue = (data: TQuestionItem[]): TQuestionItem[] => {
  return data.map(el => ({
    ...el,
    id: Number(el.id),
    ball: Number(el.ball),
    dep: Number(el.dep),
  }));
};

export default { getValue };
