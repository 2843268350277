export const theme = {
  color: {
    mainGreen: '#21CF7B',
    primary: '#2E3E5C',
    secondary: '#9FA5C0',
    secondaryMid: '#D4DEEC',
    secondaryLight: '#F4F5F7',
    secondaryExtraLight: '#FEFEFF',
    tertiary: '#D5D8DE',
    accent: '#21D07C',
    accentHover: '#21D086',
    white: '#FFFFFF',
    error: '#FF3F65',
    dark: '#20272E',
    text: '#2E3E5C',
  },
  font: {},
  effect: {
    popUpShadow: '0px 4px 16px rgba(16, 24, 32, 0.1)',
    tooltipShadow:
      '0px 4px 4px rgba(0, 0, 0, 0.06), 0px 23px 40px rgba(120, 146, 130, 0.1)',
    labelShadow: '0px 22px 20px rgba(120, 146, 141, 0.12)',
    cardShadow: '0px 23px 40px rgba(120, 146, 130, 0.1)',
  },
};
