import { useEffect, useRef } from 'react';

export const usePrevious = <Value>(value: Value): Value | undefined => {
  const prevValue = useRef<Value>();

  useEffect(() => {
    if (value) {
      prevValue.current = value;
    }
  }, [value]);

  return prevValue.current;
};
