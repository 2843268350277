import React, { FC, Fragment, useContext } from 'react';
import classNames from 'classnames';
import { useCurrentRoute, useNavigation } from 'react-navi';
import { NavItemProps } from 'models/navbar';
import { Button } from 'components/Button';
import { Routes } from 'constants/routes';
import NavbarIcon, { IconName } from './NavbarIcon';
import { theme } from '../../theme';
import { ReactComponent as Chevron } from 'assets/Icons/chevron.svg';
import { CollapsedDot, CollapsedDotWrapper, NavbarItemText } from './styled';
import Tooltip from 'components/Tooltip';
import { NavbarCollapseContext } from 'context/navbarCollapse';

export const NavItem: FC<NavItemProps> = ({
  name,
  children = [],
  icon,
  hoveredIcon,
  isOpen,
  onClick,
}) => {
  const { navigate } = useNavigation();
  const { url } = useCurrentRoute();

  const { isCollapsed, setIsCollapsed } = useContext(NavbarCollapseContext);

  const isActive = (linkTo?: string): boolean => {
    return linkTo === url.pathname || linkTo === `${url.pathname}/`;
  };

  return (
    <div>
      <Button
        className={`selectCategory ${(isOpen && 'isOpen') || ''}`}
        onClick={onClick}
      >
        {isOpen ? (
          <NavbarIcon
            name={hoveredIcon as IconName}
            fill={theme.color.accent}
          />
        ) : (
          <NavbarIcon name={icon as IconName} fill={theme.color.accent} />
        )}
        <NavbarItemText isCollapsed={isCollapsed}>{name}</NavbarItemText>
        {!isCollapsed && (
          <Chevron
            className="chevron"
            style={{
              display: !children.length ? 'none' : 'inline-block',
              transform: isOpen ? 'rotate(-180deg)' : 'rotate(0)',
              fill: isOpen ? theme.color.white : theme.color.primary,
            }}
          />
        )}
      </Button>
      <div
        style={{
          display: isOpen ? 'flex' : 'none',
          transition: 'height 0.7s ease',
        }}
        className="subListItem"
      >
        {children.length !== 0 &&
          children.map(({ name, linkTo, children }, index) =>
            linkTo ? (
              <Button
                className={classNames('listItem', {
                  active: isActive(linkTo),
                })}
                onClick={() => {
                  navigate(linkTo);
                  if (window.innerWidth < 965) {
                    setIsCollapsed(true);
                  }
                }}
                key={index}
                id={'list-item'}
              >
                {isCollapsed ? (
                  <Tooltip text={name}>
                    <CollapsedDotWrapper>
                      <CollapsedDot isActive={isActive(linkTo)}>
                        <div className="dot"></div>
                      </CollapsedDot>
                    </CollapsedDotWrapper>
                  </Tooltip>
                ) : (
                  name
                )}
              </Button>
            ) : (
              <Fragment key={index}>
                <span style={{ fontWeight: 700 }}>{name}</span>
                <ul>
                  {children?.map(({ name: subAlt, linkTo: subLink }, idx) => (
                    <li key={idx}>
                      <Button
                        onClick={() => navigate(subLink || Routes.Default)}
                        className={classNames({
                          active: isActive(subLink),
                        })}
                      >
                        {subAlt}
                      </Button>
                    </li>
                  ))}
                </ul>
              </Fragment>
            ),
          )}
      </div>
    </div>
  );
};
