import api from './index';
import product from 'api/services/product.service';
import {
  ProductCategoryFormat,
  TFullProductCategories,
} from '../../models/productGuide';
import buildFormDataQuery from '../utils/buildFormDataQuery';
import buildFormDataQueryArr from '../utils/buildFormDataQueryArr';

export const productCategoriesApi = api.injectEndpoints({
  endpoints: builder => ({
    getProductCategories: builder.query<
      TFullProductCategories[],
      ProductCategoryFormat | void
    >({
      query: (type?) => '/deps_get/',
      transformResponse: (res, meta, arg): TFullProductCategories[] =>
        arg === ProductCategoryFormat.Structure
          ? product.getFullCategory(res.data)
          : res.data.deps,
      providesTags: ['ProductCategories'],
    }),
    getGrouppedProductCategories: builder.query<
      TFullProductCategories[],
      ProductCategoryFormat | void
    >({
      query: (type?) => '/deps_get/?group=1',
      transformResponse: res => res.data.deps,
      providesTags: ['ProductCategories'],
    }),
    editCategory: builder.mutation<any, any>({
      query: buildFormDataQueryArr('/deps_edit/'),
      invalidatesTags: ['ProductCategories'],
    }),
    addCategory: builder.mutation({
      query: buildFormDataQuery('/deps_add/'),
      invalidatesTags: ['ProductCategories'],
    }),
    deleteCategory: builder.mutation({
      query: buildFormDataQueryArr('/deps_del/'),
      invalidatesTags: ['ProductCategories'],
    }),
  }),
});

export const {
  useGetProductCategoriesQuery,
  useGetGrouppedProductCategoriesQuery,
  useLazyGetProductCategoriesQuery,
  useAddCategoryMutation,
  useDeleteCategoryMutation,
  useEditCategoryMutation,
} = productCategoriesApi;
