import { createSlice } from '@reduxjs/toolkit';
import { RootStore } from '../../types';
import { TDensity } from '../../../models/feeding';
import { feedingDensityApi } from '../../services/feeding/density';

interface DensityFeedingState {
  items: Array<TDensity>;
}

const initialState: DensityFeedingState = {
  items: [],
};

const { getFeedingDensity } = feedingDensityApi.endpoints;

const slice = createSlice({
  name: 'densityFeeding',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder.addMatcher(
      getFeedingDensity.matchFulfilled,
      (state: DensityFeedingState, { payload }) => {
        state.items = payload;
      },
    ),
});
export const selectWeekFeedings = (state: RootStore): [] =>
  state.densityFeeding.items;

export const { reducer: densityFeedingReducer, actions: weekFeedingActions } =
  slice;
