import { createSlice } from '@reduxjs/toolkit';
import { RootStore } from '../types';
import { TDdopProduct } from '../../models/dops';
import { TQuestionItem } from '../../models/questions';
import { questionsApi } from '../services/questions';

interface QuestionsState {
  items: TQuestionItem[];
}

const initialState: QuestionsState = {
  items: [],
};
const { getQuestions } = questionsApi.endpoints;

const slice = createSlice({
  name: 'questions',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder.addMatcher(
      getQuestions.matchFulfilled,
      (state: QuestionsState, { payload }) => {
        state.items = payload.items;
      },
    ),
});
export const selectQuestions = (state: RootStore): TDdopProduct[] =>
  state.questions.items;

export const { reducer: questionsReducer, actions: questionsActions } = slice;
