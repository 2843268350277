import { RefObject, useEffect, useRef } from 'react';

export const useOutsideClick = (
  callback: (e: MouseEvent) => void,
): RefObject<HTMLDivElement> => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (e: MouseEvent): void => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        callback(e);
      }
    };

    document.addEventListener('click', e => handleClick(e), true);

    return () => {
      document.removeEventListener('click', e => handleClick(e), true);
    };
  }, [ref]);

  return ref;
};
