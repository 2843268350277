import styled from 'styled-components';

export const LinkWrapper = styled.div`
  display: flex;
  width: 216px;
  height: 62px;
  padding: 19px 24px;
  align-items: center;
  gap: 12px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 23px 40px 0px rgba(120, 146, 130, 0.1);
  cursor: pointer;

  .unhovered-icon {
    display: block;
  }

  .hovered-icon {
    display: none;
  }

  :hover {
    color: #fff;
    background-color: #21d07c;
    transition: background-color 0.4s ease;

    span > svg {
      path {
        fill: #fff;
        stroke: #21d07c;
        stroke-width: 0.1px;
      }
    }

    .unhovered-icon {
      display: none;
    }
    .hovered-icon {
      display: block;
    }
  }
`;

export const LinkName = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 160%;
`;
