import React, { FC, SVGProps } from 'react';
import { ReactComponent as MenuActivity } from '../../assets/Icons/menu-activity.svg';
import { ReactComponent as HoveredMenuActivity } from '../../assets/Icons/hovered-menu-activity.svg';
import { ReactComponent as MenuDiseases } from '../../assets/Icons/menu-diseases.svg';
import { ReactComponent as HoveredMenuDiseases } from '../../assets/Icons/hovered-menu-diseases.svg';
import { ReactComponent as MenuFeeding } from '../../assets/Icons/menu-feeding.svg';
import { ReactComponent as HoveredMenuFeeding } from '../../assets/Icons/hovered-menu-feeding.svg';
import { ReactComponent as MenuNotes } from '../../assets/Icons/menu-notes.svg';
import { ReactComponent as HoveredMenuNotes } from '../../assets/Icons/hovered-menu-notes.svg';
import { ReactComponent as MenuPortion } from '../../assets/Icons/menu-portion.svg';
import { ReactComponent as HoveredMenuPortion } from '../../assets/Icons/hovered-menu-portion.svg';
import { ReactComponent as MenuProducts } from '../../assets/Icons/menu-products.svg';
import { ReactComponent as HoveredMenuProducts } from '../../assets/Icons/hovered-menu-products.svg';
import { ReactComponent as MenuTechCard } from '../../assets/Icons/menu-techcards.svg';
import { ReactComponent as HoveredMenuTechCard } from '../../assets/Icons/hovered-menu-techcards.svg';
import { ReactComponent as MenuIndicators } from '../../assets/Icons/menu-indicators.svg';
import { ReactComponent as HoveredMenuIndicators } from '../../assets/Icons/hovered-menu-indicators.svg';

export type IconName =
  | 'menu-activity'
  | 'menu-diseases'
  | 'menu-feeding'
  | 'menu-notes'
  | 'menu-portion'
  | 'menu-products'
  | 'menu-techcards'
  | 'menu-indicators'
  | 'hovered-menu-activity'
  | 'hovered-menu-diseases'
  | 'hovered-menu-feeding'
  | 'hovered-menu-notes'
  | 'hovered-menu-portion'
  | 'hovered-menu-products'
  | 'hovered-menu-techcards'
  | 'hovered-menu-indicators';

const icons: Record<IconName, FC> = {
  'menu-activity': MenuActivity,
  'menu-diseases': MenuDiseases,
  'menu-feeding': MenuFeeding,
  'menu-notes': MenuNotes,
  'menu-portion': MenuPortion,
  'menu-products': MenuProducts,
  'menu-techcards': MenuTechCard,
  'menu-indicators': MenuIndicators,
  'hovered-menu-activity': HoveredMenuActivity,
  'hovered-menu-diseases': HoveredMenuDiseases,
  'hovered-menu-feeding': HoveredMenuFeeding,
  'hovered-menu-notes': HoveredMenuNotes,
  'hovered-menu-portion': HoveredMenuPortion,
  'hovered-menu-products': HoveredMenuProducts,
  'hovered-menu-techcards': HoveredMenuTechCard,
  'hovered-menu-indicators': HoveredMenuIndicators,
};

interface NavbarIconProps extends SVGProps<SVGSVGElement> {
  name: IconName;
}

const NavbarIcon: FC<NavbarIconProps> = ({ name, ...props }) => {
  const Icon: FC | undefined = icons[name];
  if (!Icon) return null;
  return <Icon style={{ minWidth: '24px' }} {...props} />;
};

export default NavbarIcon;
