export enum FILTER_STORAGE_NAMES {
  DEFAULT = 'filter',
  TECHRACRDS = 'tech-cards-filter',
}

export enum Filters {
  IdDep = 'id_dep',
  IdType = 'id_type',
  IsCarb = 'is_carb',
  IsBel = 'is_protein',
  IsFat = 'is_fat',
  Label = 'label',
  Id = 'id',
}

export type TFilter = {
  param: string;
  value: number | string | boolean;
  inputId?: string;
};

export interface FilterContextProps {
  setFilter: (param?: TFilter[]) => void;
  removeFilterItem: (param?: TFilter[]) => void;
  removeFilter: () => void;
  editFilter: (param?: TFilter) => void;
  filter: TFilter[] | null;
}
