import api from './index';
import { TDdopProduct } from '../../models/dops';
import { TResult } from '../../models/all';
import buildFormDataQuery from '../utils/buildFormDataQuery';

export type TDopAddParams = {
  name: string | number;
  p1?: string | number;
  p0?: string | number;
};

export type TDopEditParams = {
  id_item: number;
  p1?: string | number;
  p0?: string | number;
};

export const dopProductsApi = api.injectEndpoints({
  endpoints: builder => ({
    getDopProducts: builder.query<TDdopProduct[], string | void>({
      query: (name?) => ({
        url: '/products_dop_get/',
        params: { name },
      }),
      transformResponse: (res): TDdopProduct[] =>
        res.data.products_dop.map((product: TDdopProduct) => ({
          ...product,
          id: Number(product.id),
        })),
      providesTags: ['DopProducts'],
    }),
    addDopProduct: builder.mutation<
      TResult<{ products_dop: number }>,
      TDopAddParams
    >({
      query: buildFormDataQuery('/products_dop_add/'),
      invalidatesTags: ['DopProducts'],
    }),

    editDopProduct: builder.mutation<
      TResult<{ products_dop: number }>,
      TDopEditParams
    >({
      query: buildFormDataQuery('/products_dop_edit/'),
      invalidatesTags: ['DopProducts'],
    }),

    deleteDopProduct: builder.mutation<
      TResult<{ products_dop: number }>,
      TDopEditParams
    >({
      query: buildFormDataQuery('/products_dop_del/'),
      invalidatesTags: ['DopProducts'],
    }),
  }),
});

export const {
  useGetDopProductsQuery,
  useLazyGetDopProductsQuery,
  useAddDopProductMutation,
  useEditDopProductMutation,
  useDeleteDopProductMutation,
} = dopProductsApi;
