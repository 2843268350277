import styled, { css } from 'styled-components/macro';
import { hexToRGB } from 'helpers/hexToRgb';
import { theme } from 'theme';

export const ScNavbar = styled.nav<{ isCollapsed: boolean }>`
  position: relative;
  width: 14.375rem;
  min-height: 100%;
  width: ${({ isCollapsed }) => (isCollapsed ? '96px' : '14.375rem')};
  max-width: ${({ isCollapsed }) => (isCollapsed ? '96px' : '14.375rem')};
  transition: all 0.2s ease;
  padding: 2rem 1rem;
  background-color: ${theme.color.secondaryLight};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  @media (max-width: 965px) {
    box-shadow: ${({ isCollapsed }) =>
      isCollapsed ? 'none' : '10px 0px 10px -5px rgba(0, 0, 0, 0.3)'};
  }

  .navbar {
    display: grid;
    place-content: center;
    gap: 0.75rem;

    .collapsed-toggle {
      position: absolute;
      right: -0;
      top: 2rem;
      width: 1rem;
      height: 1.5rem;
      background-color: ${theme.color.white};
      border-radius: 0.5rem 0 0 0.5rem;

      svg {
        width: 0.6rem;

        path {
          fill: ${theme.color.dark};
        }
      }
    }
  }

  & > button,
  .selectCategory {
    position: relative;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 160%;
    text-align: left;
    cursor: pointer;
    color: ${theme.color.primary};

    transition: all 0.2 ease;
    display: flex;
    align-items: center;
    padding: 12px 22px;
    gap: 12px;

    border-radius: 12px;
    width: 216px;
    width: ${({ isCollapsed }) => (isCollapsed ? '70px' : '216px')};
    height: 48px;

    svg {
      width: 1.5rem;
    }

    &:hover {
      background: ${hexToRGB(theme.color.accent, 0.1)};
    }

    &.isOpen {
      background: ${theme.color.accent};
      color: ${theme.color.white};
    }
    p {
      margin-right: 1rem;
    }

    .chevron {
      position: absolute;
      top: 1.25rem;
      right: 1rem;

      width: 0.6rem;
      transition: all 0.4s ease;
    }
  }

  ul {
    padding-left: 17px;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    li {
      list-style: none;
    }

    li button {
      position: relative;
      text-decoration: none;
      color: ${theme.color.primary};
      font-weight: 500;
      font-size: 12px;
      line-height: 160%;
      padding: 6px 0;
      box-sizing: border-box;
      text-align: left;
    }
  }

  .subListItem {
    padding-left: 3.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    animation: growDown 300ms ease-in-out;
    transform-origin: top center;

    & > span,
    & > button {
      position: relative;
      text-decoration: none;
      color: ${theme.color.primary};
      font-family: 'Montserrat', sans-serif;
      font-size: 12px;
      line-height: 160%;
      padding: 0.3rem;
      box-sizing: border-box;
      text-align: left;
    }

    .active {
      &:before {
        position: absolute;
        content: '\\2022';
        color: green;
        font-weight: bold;
        display: inline-block;
        width: 1rem;
        margin-left: -1rem;
        color: #2e3e5c;
        font-size: 1.3em;
      }
    }

    & > button {
      &:hover,
      &.active {
        color: #2e3e5c;
        font-weight: 600;
      }
    }

    .listItem {
      padding: 0.5rem 0;

      &:before {
        position: absolute;
        font-weight: bold;
        display: inline-block;
        width: 1rem;
        margin-left: -1rem;
        margin-left: ${({ isCollapsed }) => (isCollapsed ? '-24px' : '-16px')};
        margin-top: ${({ isCollapsed }) => (isCollapsed ? '20px' : '')};
        color: ${theme.color.accent};
      }
    }

    @keyframes growDown {
      0% {
        transform: scaleY(0);
      }
      100% {
        transform: scaleY(1);
      }
    }

    ${({ isCollapsed }) =>
      isCollapsed &&
      css`
        .logo {
          visibility: hidden;
        }

        .menu {
          &-item {
            width: 3.5rem;

            span {
              p {
                display: none;
              }
            }
          }
        }
      `}
  }
`;

export const NavbarItemText = styled.p<{ isCollapsed?: boolean }>`
  display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'block')};
`;

export const CollapsedDotWrapper = styled.div`
  position: relative;
  margin-top: 3px;
`;

export const CollapsedDot = styled.div<{ isActive: boolean }>`
  position: absolute;
  left: -37px;
  width: 30px;
  height: 20px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ isActive }) =>
    isActive &&
    css`
      .selectCategory {
        width: 70px;
      }
    `}

  .dot {
    background: ${({ isActive }) =>
      isActive ? `${theme.color.mainGreen}` : `${theme.color.primary}`};
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin-left: 1px;
    margin-top: -0.2px;
  }

  &:hover {
    background: ${theme.color.mainGreen};

    .dot {
      background: #fff;
    }
  }
`;
