import api from './index';
import buildFormDataQuery from '../utils/buildFormDataQuery';
import { TFormulasItem } from 'models/formulas';

export const formulasApi = api.injectEndpoints({
  endpoints: builder => ({
    getFormulas: builder.query<TFormulasItem[] | undefined, void>({
      query: () => '/sprav_formula_get/',
      transformResponse: (res): any => res.data.sprav_formula,
      providesTags: ['Formulas'],
    }),
    addFormula: builder.mutation({
      query: buildFormDataQuery('/sprav_formula_add/'),
      invalidatesTags: ['Formulas'],
    }),
    editFormula: builder.mutation({
      query: buildFormDataQuery('/sprav_formula_edit/'),
      invalidatesTags: ['Formulas'],
    }),
    deleteFormula: builder.mutation({
      query: buildFormDataQuery('/sprav_formula_del/'),
      invalidatesTags: ['Formulas'],
    }),
  }),
});

export const {
  useGetFormulasQuery,
  useAddFormulaMutation,
  useEditFormulaMutation,
  useDeleteFormulaMutation,
} = formulasApi;
