import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit';
import slices from './slices';
import api from './services/index';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';

export const createStore = (
  options?: ConfigureStoreOptions['preloadedState'] | undefined,
): ToolkitStore =>
  configureStore({
    reducer: {
      ...slices,
      [api.reducerPath]: api.reducer,
    },

    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().concat(api.middleware),
    ...options,
  });

export const store = createStore();
