export const getPropsObj = (
  props: Record<string, unknown>,
): Record<string, unknown> => {
  return Object.entries(props).reduce(
    (acc, curr) => ({
      ...acc,
      ...(curr[1] !== undefined ? { [curr[0]]: curr[1] } : null),
    }),
    {},
  );
};

export const getFormData = (obj: Record<string, unknown>): FormData => {
  const formData = new FormData();

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (value instanceof Blob) {
        formData.append(key, value, value.name);
      } else if (value === null) {
        formData.append(key, '0');
      } else {
        formData.append(key, String(value));
      }
    }
  }

  return formData;
};

export const getFormDataArr = (obj: Record<string, unknown>): FormData => {
  const formData = new FormData();

  const appendToFormData = (key: string, value: any) => {
    if (value instanceof Blob) {
      formData.append(key, value, value.name);
    } else if (Array.isArray(value)) {
      value.forEach((item, index) => {
        formData.append(`${key}[${index}]`, String(item));
      });
    } else {
      formData.append(key, String(value));
    }
  };

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      appendToFormData(key, value);
    }
  }

  return formData;
};
