import api from './index';
import buildFormDataQuery from '../utils/buildFormDataQuery';
import { TWaistHipRatioItem } from 'models/waistHipRatio';

export const waistHipRatioApi = api.injectEndpoints({
  endpoints: builder => ({
    getWaistHipRatio: builder.query<TWaistHipRatioItem[] | undefined, void>({
      query: () => '/sprav_otob_get/',
      transformResponse: (res): any => res.data.sprav_otob,
      providesTags: ['WaistHipRatio'],
    }),
    addWaistHipRatio: builder.mutation({
      query: buildFormDataQuery('/sprav_otob_add/'),
      invalidatesTags: ['WaistHipRatio'],
    }),
    editWaistHipRatio: builder.mutation({
      query: buildFormDataQuery('/sprav_otob_edit/'),
      invalidatesTags: ['WaistHipRatio'],
    }),
    deleteWaistHipRatio: builder.mutation({
      query: buildFormDataQuery('/sprav_otob_del/'),
      invalidatesTags: ['WaistHipRatio'],
    }),
  }),
});

export const {
  useGetWaistHipRatioQuery,
  useAddWaistHipRatioMutation,
  useEditWaistHipRatioMutation,
  useDeleteWaistHipRatioMutation,
} = waistHipRatioApi;
