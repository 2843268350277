import styled from 'styled-components';
import { theme } from 'theme';

export const ScLogo = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.color.primary};
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none;
  line-height: 1.5rem;

  padding-bottom: 1.5rem;

  .collapsed {
    width: 28px;
    height: 24px;
  }

  svg {
    width: 10.5rem;
    height: 1.5rem;
    vertical-align: middle;
  }
`;
