import api from './index';
import buildFormDataQuery from '../utils/buildFormDataQuery';
import { TBodyWeightItem } from 'models/bodyWeight';

export const bodyWeightApi = api.injectEndpoints({
  endpoints: builder => ({
    getBodyWeights: builder.query<TBodyWeightItem[] | undefined, void>({
      query: () => '/sprav_imt_get/',
      transformResponse: (res): any => res.data.sprav_imt,
      providesTags: ['BodyWeights'],
    }),
    addBodyWeight: builder.mutation({
      query: buildFormDataQuery('/sprav_imt_add/'),
      invalidatesTags: ['BodyWeights'],
    }),
    editBodyWeight: builder.mutation({
      query: buildFormDataQuery('/sprav_imt_edit/'),
      invalidatesTags: ['BodyWeights'],
    }),
    deleteBodyWeights: builder.mutation({
      query: buildFormDataQuery('/sprav_imt_del/'),
      invalidatesTags: ['BodyWeights'],
    }),
  }),
});

export const {
  useGetBodyWeightsQuery,
  useAddBodyWeightMutation,
  useEditBodyWeightMutation,
  useDeleteBodyWeightsMutation,
} = bodyWeightApi;
