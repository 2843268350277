import buildFormDataQuery from '../../utils/buildFormDataQuery';
import { TFeedingProteinsItem } from '../../../models/feeding';
import api from '../index';
import { TResult } from '../../../models/all';
import service from '../../../api/services/feeding.service';

export const feedingProteinApi = api.injectEndpoints({
  endpoints: builder => ({
    getFeedingProtein: builder.query<TFeedingProteinsItem[], void>({
      query: () => '/sprav_protein_get/',
      transformResponse: (res): TFeedingProteinsItem[] =>
        service.proteinsGet(res.data.sprav_protein),
      providesTags: ['FeedingProtein'],
    }),
    editFeedingProtein: builder.mutation<
      TResult<{ sprav_protein: number }>,
      Record<string, string | number>
    >({
      query: buildFormDataQuery('/sprav_protein_edit/'),
      invalidatesTags: ['FeedingProtein'],
    }),
    addFeedingProtein: builder.mutation<
      TResult<{ sprav_protein: number }>,
      Record<string, string | number>
    >({
      query: buildFormDataQuery('/sprav_protein_add/'),
      invalidatesTags: ['FeedingProtein'],
    }),
    deleteFeedingProtein: builder.mutation<
      TResult<{ sprav_protein: number }>,
      { id_item: string | number }
    >({
      query: buildFormDataQuery('/sprav_protein_del/'),
      invalidatesTags: ['FeedingProtein'],
    }),
  }),
});

export const {
  useGetFeedingProteinQuery,
  useAddFeedingProteinMutation,
  useEditFeedingProteinMutation,
  useDeleteFeedingProteinMutation,
} = feedingProteinApi;
