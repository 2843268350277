import { createSlice } from '@reduxjs/toolkit';
import { TFeedingCountItem } from '../../../models/feeding';
import { feedingCountApi } from '../../services/feeding/count';

interface CountFeedingState {
  items: Array<TFeedingCountItem>;
}

const initialState: CountFeedingState = {
  items: [],
};

const { getFeedingCount } = feedingCountApi.endpoints;

const slice = createSlice({
  name: 'countFeeding',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder.addMatcher(
      getFeedingCount.matchFulfilled,
      (state: CountFeedingState, { payload }) => {
        state.items = payload;
      },
    ),
});

export const { reducer: countFeedingReducer, actions: countFeedingActions } =
  slice;
