import { createSlice } from '@reduxjs/toolkit';
import { RootStore } from '../types';
import { productDependencies } from '../services/productDependencies';
import { TechCardDdepType } from '../../models/techCards';

interface ProductDependenciesState {
  dependency: TechCardDdepType[];
  subdependency: TechCardDdepType[];
}

const initialState: ProductDependenciesState = {
  dependency: [],
  subdependency: [],
};
const { getProductDependency, getProductSubdependency } =
  productDependencies.endpoints;

const slice = createSlice({
  name: 'ProductDependencies',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addMatcher(
        getProductDependency.matchFulfilled,
        (state: any, { payload }) => {
          state.dependency = payload;
        },
      )
      .addMatcher(
        getProductSubdependency.matchFulfilled,
        (state: ProductDependenciesState, { payload }) => {
          state.subdependency = payload;
        },
      ),
});

export const selectProductDependencies = (
  state: RootStore,
): TechCardDdepType[] => state.productDependencies.dependency;

export const selectProductSubDependencies = (
  state: RootStore,
): TechCardDdepType[] => state.productDependencies.subdependency;

export const {
  reducer: productDependenciesReducer,
  actions: productDependenciesActions,
} = slice;
