export const enum AlertStatus {
  Success = 'success',
  Error = 'error',
  Default = 'default',
}
export type Ttext = {
  headline: string;
  body: string;
};

export interface AlertContextProps {
  alertIsOpen: boolean;
  setAlert: (t: AlertStatus, data: Ttext) => void;
  type: AlertStatus;
  text: Ttext;
}

export interface ScAlertProps {
  type: AlertStatus;
}
