import { Routes } from 'constants/routes';
import { IconName } from '../components/Navbar/NavbarIcon';

export interface INavItems {
  name: string;
  icon?: IconName;
  hoveredIcon?: IconName;
  children?: INavItems[];
  linkTo?: string;
}

export const navItems: Array<INavItems> = [
  {
    name: 'Нормы',
    icon: 'menu-portion',
    hoveredIcon: 'hovered-menu-portion',
    children: [
      {
        name: 'Нормы нутриентов',
        linkTo: `${Routes.NutritionalStandards}`,
      },
      {
        name: 'Размеры порций',
        linkTo: `${Routes.PortionSize}`,
      },
    ],
  },
  {
    name: 'Питание',
    icon: 'menu-feeding',
    hoveredIcon: 'hovered-menu-feeding',
    children: [
      {
        name: 'Кол-во продуктов',
        linkTo: Routes.FeedingWeekProducts,
      },
      {
        name: 'Кол-во приемов',
        linkTo: Routes.FeedingCount,
      },
      {
        name: 'Плотность приемов',
        linkTo: Routes.FeedingDensity,
      },
      {
        name: 'Интервал голодания',
        linkTo: Routes.FeedingInterval,
      },
      {
        name: 'Виды диет',
        linkTo: Routes.FeedingDiets,
      },
      {
        name: 'Источник БЖУ',
        linkTo: Routes.FeedingPercentage,
      },
    ],
  },

  {
    name: 'Продукты',
    icon: 'menu-products',
    hoveredIcon: 'hovered-menu-products',
    children: [
      {
        name: 'Дерево категорий',
        linkTo: Routes.CategoryTree,
      },
      {
        name: 'Справочник продуктов',
        linkTo: Routes.ProductGuide,
      },
      {
        name: 'Совмест. витаминов',
        linkTo: Routes.Vitamins,
      },
      {
        name: 'Нормы ГИ',
        linkTo: Routes.GlycemicIndex,
      },
      {
        name: 'Удаленные продукты',
        linkTo: Routes.ProductsDel,
      },
    ],
  },
  {
    name: 'Активности и Профессии',
    icon: 'menu-activity',
    hoveredIcon: 'hovered-menu-activity',
    children: [
      {
        name: 'Активности',
        linkTo: Routes.Activities,
      },
      {
        name: 'Профессии',
        linkTo: Routes.Professions,
      },
      {
        name: 'Категории активностей',
        linkTo: Routes.ActivitiesGroups,
      },
    ],
  },
  {
    name: 'Показатели здоровья',
    icon: 'menu-indicators',
    hoveredIcon: 'hovered-menu-indicators',
    children: [
      {
        name: 'Индекс массы тела',
        linkTo: Routes.BodyWeight,
      },
      {
        name: 'Индекс ОТ/ОБ',
        linkTo: Routes.WaistHipRatio,
      },
      {
        name: 'Справочник формул',
        linkTo: Routes.Formulas,
      },
      {
        name: 'Типы фигур',
        linkTo: Routes.BodyTypes,
      },
    ],
  },
  {
    name: 'Анализы и болезни',
    icon: 'menu-diseases',
    hoveredIcon: 'hovered-menu-diseases',
    children: [
      {
        name: 'Анализы',
        linkTo: Routes.Analyses,
      },
      {
        name: 'Болезни',
        linkTo: Routes.Diseases,
      },
      {
        name: 'Вопросы',
        linkTo: Routes.Questions,
      },
      {
        name: 'Клиники',
        linkTo: Routes.Clinics,
      },
      {
        name: 'Системы здоровья',
        linkTo: Routes.HealthSystems,
      },
      {
        name: 'Подкатегории систем',
        linkTo: Routes.HealthSubSystems,
      },
    ],
  },
  {
    name: 'Тех. Карты',
    icon: 'menu-techcards',
    hoveredIcon: 'hovered-menu-techcards',
    children: [
      {
        name: 'Дерево тех. карт',
        linkTo: Routes.TechCardsDeps,
      },
      {
        name: 'Справочник тех. карт',
        linkTo: `${Routes.TechCards}`,
      },
      {
        name: 'Удаленные тех. карты',
        linkTo: Routes.TechCardsDel,
      },
    ],
  },
  {
    name: 'Другое',
    icon: 'menu-notes',
    hoveredIcon: 'hovered-menu-notes',
    children: [
      {
        name: 'Примечание',
        linkTo: `${Routes.Notes}`,
      },
      {
        name: 'Страны',
        linkTo: `${Routes.Countries}`,
      },
    ],
  },
];
