export enum Device {
  MOBILE_XS = 577,
  MOBILE_S = 767,
  MOBILE = 991,
  TABLET = 1170,
  DESKTOP = 1280,
}

export const device = {
  mobileXS: `${Device.MOBILE_XS}px`,
  mobileS: `${Device.MOBILE_S}px`,
  mobile: `${Device.MOBILE}px`,
  tablet: `${Device.TABLET}px`,
  desktop: `${Device.DESKTOP}px`,
};

export const media = {
  mobileXS: `max-width: ${device.mobileXS}`,
  mobileS: `max-width: ${device.mobileS}`,
  mobile: `max-width: ${device.mobile}`,
  tablet: `max-width: ${device.tablet}`,
  desktop: `max-width: ${device.desktop}`,
};

export const padding = `16px`;
