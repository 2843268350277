import React from 'react';
import styled from 'styled-components/macro';

import error from 'assets/images/404.png';

export const NotFountPage = (): JSX.Element => {
  return (
    <ScNotFountPage>
      <div className="imgwrp">
        <img src={error} alt="error" />
        <h1>Ops, page not found</h1>
      </div>
      <a href={'/'}>Return to Home page</a>
    </ScNotFountPage>
  );
};

const ScNotFountPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 20px);
  .imgwrp {
    max-width: 705px;
    img {
      width: 100%;
      height: auto;
    }
  }
  h1 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.02em;
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 32px;
    width: 188px;
    height: 40px;
    background: #2e3e5c;
    border-radius: 148px;
    text-decoration: none;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    margin: 54px 0 0 0;
  }
`;
