import { createSlice } from '@reduxjs/toolkit';
import { RootStore } from '../../types';
import { TFeedingProteinsItem } from '../../../models/feeding';
import { feedingCarbsApi } from '../../services/feeding/carbs';

interface CarbsFeedingState {
  items: Array<TFeedingProteinsItem>;
}

const initialState: CarbsFeedingState = {
  items: [],
};

const {
  getFeedingCarbs,
  // addFeedingInterval,
  // editFeedingInterval,
  // deleteFeedingInterval,
} = feedingCarbsApi.endpoints;

const slice = createSlice({
  name: 'carbsFeeding',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder.addMatcher(
      getFeedingCarbs.matchFulfilled,
      (state: CarbsFeedingState, { payload }) => {
        state.items = payload;
      },
    ),
});
export const selectFeedingCarbs = (state: RootStore): [] =>
  state.carbsFeeding.items;

export const { reducer: feedingCarbsReducer, actions: feedingCarbsActions } =
  slice;
