import styled, { css } from 'styled-components/macro';
import { theme } from '../../theme/index';
import { ButtonTheme, ScButtonProps } from 'models/button';

export const ScButton = styled.button<ScButtonProps>`
  min-width: ${({ minWidth }) => (minWidth ? minWidth : 'unset')};
  text-align: center;
  display: flex;
  align-items: center;
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : '60px'};
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  transition: 0.2s;
  & > * {
    vertical-align: middle;
  }
  &.goBack {
    width: 24px;
    height: 24px;
    background: ${theme.color.secondaryLight};
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
  }
  &.shadow {
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  }
  &:disabled {
    pointer-events: none;
    svg {
      filter: grayscale(1);
      opacity: 0.3;
    }
  }

  ${({ color }) => {
    if (!color) return;
    switch (color) {
      case ButtonTheme.Primary:
        return css`
          color: ${theme.color.white};
          background: ${theme.color.accent};
          padding: 9px 16px;
          svg {
            path,
            circle {
              stroke: ${theme.color.white};
              fill: ${theme.color.white};
            }
          }
          &:hover {
            background: ${theme.color.accentHover};
          }

          &:disabled {
            background: #a5eccb;
          }
        `;
      case ButtonTheme.Outline:
        return css`
          color: ${theme.color.dark};
          background: ${theme.color.white};
          padding: 9px 16px;
          border: 1px solid ${theme.color.white};

          svg {
            path,
            circle {
              fill: ${theme.color.dark};
            }
          }
          &:hover {
            background: ${theme.color.white};
          }

          &:disabled {
            background: ${theme.color.secondaryMid};
          }
        `;
      case ButtonTheme.Blue:
        return css`
          color: #2e3e5c;
          background: #e1ecfe;
          padding: 7px 10px;
          border: 1px solid ${theme.color.white};
          font-weight: 500 !important;

          svg {
            path,
            circle {
              fill: ${theme.color.dark};
            }
          }

          &:disabled {
            background: ${theme.color.secondaryMid};
          }
        `;
      case ButtonTheme.Transparent:
        return css`
          color: #2e3e5c;
          background: transparent;
          padding: 9px 16px;
          border: 1px solid ${theme.color.white};

          svg {
            path,
            circle {
              fill: ${theme.color.dark};
            }
          }

          &:hover {
            background: #f4f5f7;
          }

          &:disabled {
            background: ${theme.color.secondaryMid};
          }
        `;
      case ButtonTheme.TransparentMini:
        return css`
          color: #2e3e5c;
          background: transparent;
          padding: 7px 10px;
          border: 1px solid ${theme.color.white};

          &:hover {
            background: #f4f5f7;
          }
        `;
      case ButtonTheme.Yellow:
        return css`
          color: ${theme.color.text};
          background: ${ButtonTheme.Yellow};
          padding: 9px 16px;
          border: 1px solid ${theme.color.white};
        `;
      case ButtonTheme.Violet:
        return css`
          color: ${theme.color.text};
          background: ${ButtonTheme.Violet};
          padding: 9px 16px;
          border: 1px solid ${theme.color.white};
        `;
      case ButtonTheme.Green:
        return css`
          color: ${theme.color.text};
          background: ${ButtonTheme.Green};
          padding: 9px 16px;
          border: 1px solid ${theme.color.white};
        `;
      case ButtonTheme.LightBlue:
        return css`
          color: ${theme.color.text};
          background: ${ButtonTheme.LightBlue};
          padding: 9px 16px;
          border: 1px solid ${theme.color.white};
        `;
      case ButtonTheme.Pink:
        return css`
          color: ${theme.color.text};
          background: ${ButtonTheme.Pink};
          padding: 9px 16px;
          border: 1px solid ${theme.color.white};
        `;
      case ButtonTheme.Sand:
        return css`
          color: ${theme.color.text};
          background: ${ButtonTheme.Sand};
          padding: 9px 16px;
          border: 1px solid ${theme.color.white};
        `;
      default:
        return css`
          color: ${theme.color.primary};
          background: ${theme.color.secondaryLight};
          padding: 9px 16px;
          svg {
            path {
              stroke: ${theme.color.accent};
            }
            circle {
              fill: ${theme.color.accent};
            }
          }
          &:hover {
            background: #f0f0f0;
          }

          &:disabled {
            background: #f4f5ed;
            color: ${theme.color.secondary};
          }
        `;
    }
  }}
`;
