import React, { FC, useContext } from 'react';
import { ScAlert, ScAlertContainer } from './styled';
import { AlertContext } from 'context/alert';

export const Alert: FC = () => {
  const { alertIsOpen, type, text } = useContext(AlertContext);

  return (
    <ScAlertContainer isVisible={alertIsOpen}>
      {alertIsOpen && (
        <ScAlert type={type}>
          <h3>{text.headline}</h3>
          <p>{text.body}</p>
        </ScAlert>
      )}
    </ScAlertContainer>
  );
};
