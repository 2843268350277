type BaseParam = {
  id: number;
};

export const arrayFilter = <T extends BaseParam>(
  data: Array<T>,
  avaliArray: number[],
): T[] => {
  const isAvail = (el: T) => {
    return avaliArray.includes(el.id) ? el : false;
  };

  return data.filter(isAvail);
};
