import { TLoginResponce } from 'models/login';

export enum SaveUserMode {
  Session = 'session',
  Local = 'local',
}

const login = (
  response: TLoginResponce,
  mode: SaveUserMode,
): TLoginResponce | undefined => {
  if (response.data.token) {
    switch (mode) {
      case SaveUserMode.Local:
        sessionStorage.removeItem('userData');
        localStorage.setItem('userData', JSON.stringify(response.data));
        return;
      default:
        localStorage.removeItem('userData');
        sessionStorage.setItem('userData', JSON.stringify(response.data));
        return;
    }
  }
  return response;
};

const logout = async (): Promise<void> => {
  sessionStorage.removeItem('userData');
  localStorage.removeItem('userData');
};

export default { login, logout };
