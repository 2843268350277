import React from 'react';
import { lazy, mount, route } from 'navi';
import { Params, Routes } from 'constants/routes';
import { MainPage } from 'modules/MainPage';

export const routes = mount({
  [Routes.Default]: route({
    title: 'Virtual Nutritionist',
    view: <MainPage />,
  }),
  [Routes.Login]: lazy(() => import('../auth/route')),
  [Routes.ProductGuide]: lazy(() => import('../productGuide/route')),
  [Routes.ProductsDel]: lazy(() => import('../ProductsDel/route')),
  [`${Routes.NutritionalStandards}`]: lazy(
    () => import('../nutritionalStandards/route'),
  ),
  [`${Routes.PortionSize}`]: lazy(() => import('../portionSize/route')),
  [Routes.CategoryTree]: lazy(() => import('../categoryTree/route')),
  [Routes.GlycemicIndex]: lazy(() => import('../GlycemicIndex/route')),
  [Routes.Vitamins]: lazy(() => import('../compVitaminst/route')),
  [Routes.FeedingCount]: lazy(() => import('../FeedingCount/route')),
  [Routes.FeedingDensity]: lazy(() => import('../Density/route')),
  [Routes.FeedingInterval]: lazy(() => import('../Interval/route')),
  [Routes.FeedingDiets]: lazy(() => import('../Diets/route')),
  [Routes.FeedingPercentage]: lazy(
    () => import('../NutrientsPercentage/route'),
  ),
  [Routes.FeedingWeekProducts]: lazy(
    () => import('../FeedingWeekProducts/route'),
  ),
  [Routes.Activities]: lazy(() => import('../Activities/route')),
  [Routes.ActivitiesGroups]: lazy(() => import('../ActivitiesGroups/route')),
  [Routes.Professions]: lazy(() => import('../Professions/route')),
  [Routes.BodyWeight]: lazy(() => import('../BodyWeight/route')),
  [Routes.WaistHipRatio]: lazy(() => import('../WaistHipRatio/route')),
  [Routes.Formulas]: lazy(() => import('../Formulas/route')),
  [Routes.BodyTypes]: lazy(() => import('../BodyTypes/route')),
  [Routes.Analyses]: lazy(() => import('../Analyses/route')),
  [Routes.Questions]: lazy(() => import('../Questions/route')),
  [Routes.Clinics]: lazy(() => import('../Clinics/route')),
  [Routes.ClinicsModal]: lazy(() => import('../Clinics/routeModal')),
  [Routes.HealthSystems]: lazy(() => import('../HealthSystems/route')),
  [Routes.HealthSubSystems]: lazy(() => import('../HealthSubSystems/route')),
  [`${Routes.TechCardsEdit}/${Params.TechCardsType}/${Params.Id}`]: lazy(
    () => import('../TechCardsEdit/route'),
  ),
  [Routes.TechCardsDel]: lazy(() => import('../TechCardsDel/route')),
  [Routes.TechCards]: lazy(() => import('../TechCards/route')),
  [Routes.TechCardsDeps]: lazy(() => import('../TechCardDepsTree/route')),
  [Routes.Notes]: lazy(() => import('../Notes/route')),
  [Routes.Countries]: lazy(() => import('../Countries/route')),
  [Routes.Diseases]: lazy(() => import('../Diseases/route')),
});
