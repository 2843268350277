import React, { FC, ReactNode } from 'react';
import { useCurrentRoute } from 'react-navi';
import { AuthLayout } from './Auth';
import { DefaultLayout } from './Default';
import { SpecialUrls } from 'models/routes';
import { MainPageLayout } from './MainPage';

export const Layout: FC<{ children?: ReactNode }> = ({ children }) => {
  const route = useCurrentRoute();

  switch (route.url.pathname) {
    case SpecialUrls.LOGIN:
    case SpecialUrls.RERGISTRATION:
      return <AuthLayout>{children}</AuthLayout>;

    case SpecialUrls.MAIN_PAGE:
      return <MainPageLayout>{children}</MainPageLayout>;

    default:
      return <DefaultLayout>{children}</DefaultLayout>;
  }
};
