import axios from 'axios';
import {
  ResType,
  TGetProductsParams,
  TSaveProductData,
} from 'models/productGuide';
import servie from '../services/product.service';
import { Routes } from 'constants/routes';
import { getFormData } from 'helpers/formData';

export const productGuide = async ({
  start,
  count,
  filter,
}: TGetProductsParams): Promise<ResType> => {
  const res = await axios
    .get(`/api/admin/products_get/`, {
      params: servie.getParams({ start, count, filter }),
    })
    .catch(e => {
      return e;
    });

  return res.data.data;
};

export const productEdit = async (
  params: string,
): Promise<TSaveProductData> => {
  return await axios
    .get(`/api/admin/products_edit/${params}`)
    .then(() => (window.location.href = Routes.ProductGuide))
    .catch(e => {
      return e;
    });
};

export const productRestore = async (
  id_item: number,
  value: 0 | 1 = 0,
): Promise<null> => {
  const item = { id_item, is_del: value };
  return await axios({
    method: 'post',
    url: '/api/admin/products_edit/',
    data: getFormData(item),
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const productDelete = async (id: number): Promise<null> => {
  const item = { id_item: id };
  return await axios({
    method: 'post',
    url: '/api/admin/products_del/',
    data: getFormData(item),
    headers: { 'Content-Type': 'multipart/form-data' },
  })
    .then(() => (window.location.href = Routes.ProductGuide))
    .catch(e => {
      return e;
    });
};
