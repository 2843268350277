import React, { FC, ReactNode } from 'react';
import { LinkWrapper, LinkName } from './styles';
import NavbarIcon, { IconName } from 'components/Navbar/NavbarIcon';
import { theme } from 'theme';

export interface IMainPageLinkProps {
  name: string;
  icon?: string | ReactNode;
  hoveredIcon?: string | ReactNode;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

export const MainPageLink: FC<IMainPageLinkProps> = ({
  name,
  icon = '',
  hoveredIcon = '',
  onClick,
}) => {
  return (
    <LinkWrapper onClick={onClick}>
      {typeof icon === 'string' ? (
        <div>
          <NavbarIcon
            className="unhovered-icon"
            name={icon as IconName}
            fill={theme.color.accent}
          />
          <NavbarIcon
            className="hovered-icon"
            name={hoveredIcon as IconName}
            fill={theme.color.accent}
          />
        </div>
      ) : (
        <span>{icon}</span>
      )}
      <LinkName>{name}</LinkName>
    </LinkWrapper>
  );
};
