import React, { FC, useContext } from 'react';
import { ModalContext } from 'context/modal';
import { ScModalContainer, ScModalBody, ScModal } from './styles';

export const Modal: FC = () => {
  const {
    isOpen,
    modal: children,
    params,
    modalWidth,
  } = useContext(ModalContext);

  return isOpen ? (
    <ScModalContainer>
      <ScModal modalWidth={modalWidth}>
        <ScModalBody>
          <div className="headline">
            {params.icon}
            {params.title && <h3>{params.title}</h3>}
          </div>
          {params.text && <p className="text">{params.text}</p>}
          {children}
        </ScModalBody>
      </ScModal>
    </ScModalContainer>
  ) : null;
};
