import { TFilter } from './filter';

export type ResType = { products: Product[]; total: number };

export type TNutrients = {
  a: string;
  a_al: string;
  a_ar: string;
  a_as: string;
  a_ce: string;
  a_dc: string;
  a_fe: string;
  a_gc: string;
  a_gi: string;
  a_iz: string;
  a_le: string;
  a_li: string;
  a_me: string;
  a_pr: string;
  a_se: string;
  a_ti: string;
  a_tn: string;
  a_tr: string;
  a_va: string;
  a_gl: string;
  a_td: string;
  as: string;
  b1: string;
  b2: string;
  b3: string;
  b4: string;
  b5: string;
  b6: string;
  b9: string;
  b12: string;
  ba: string;
  bi: string;
  bk: string;
  bo: string;
  c: string;
  ca: string;
  cd: string;
  cl: string;
  co: string;
  cr: string;
  cu: string;
  d: string;
  e: string;
  f: string;
  h: string;
  hg: string;
  hol: string;
  i: string;
  k: string;
  ka: string;
  li: string;
  mg: string;
  mn: string;
  mo: string;
  na: string;
  ni: string;
  o3: string;
  o6: string;
  pb: string;
  ph: string;
  pp: string;
  s: string;
  sb: string;
  se: string;
  si: string;
  sn: string;
  v: string;
  zn: string;
  zr: string;
  fat_min: string;
  fat_pol: string;
  fat_max: string;
  fibres: string;
  sugar: string;
  carbs: string;
  fats: string;
  gi: string;
  fe: string;
};

type BooleanString = '0' | '1' | null;

export type Product = TNutrients & {
  id: string;
  data: string;
  type: string;
  id_type: string;
  id_dep: string;
  id_parent: string | number;
  id_dep_parent: string;
  deps2: string;
  origin: '0' | '1' | '2';
  label: string;
  image: any;
  category_id: string;
  is_simple?: string;
  calories: string;
  proteins: string;
  nutrients: TNutrients;
  is_carb: boolean;
  is_fat: boolean;
  is_protein: boolean;
  is_del: boolean;
  is_animal: boolean;
  fats: string;
  category: string;
  subCategory: string;
  dops: string;
  ncomp: string;
  comp: string;
  is_part1: BooleanString;
  is_part2: BooleanString;
  is_part3: BooleanString;
  is_part4: BooleanString;
  is_part5: BooleanString;
  plus1: string;
  plus2: string;
  min_part2: any;
  max_part2: any;
  onClick?: () => void;
};

export type TSaveProductData = {
  type?: string;
  id_type?: string;
  id_dep?: string;
  deps2?: string;
  origin?: '0' | '1' | '2';
  label?: string;
  category_id?: string;
  calories?: string;
  proteins?: string;
  is_carb?: string;
  is_fat?: string;
  is_protein?: string;
  is_del?: string;
  is_animal?: string;
  fats?: string;
  category?: string;
  subCategory?: string;
  dops?: string;
  ncomp?: string;
  comp?: string;
  a?: string;
  a_al?: string;
  a_ar?: string;
  a_as?: string;
  a_ce?: string;
  a_dc?: string;
  a_fe?: string;
  a_gc?: string;
  a_gi?: string;
  a_iz?: string;
  a_le?: string;
  a_li?: string;
  a_me?: string;
  a_pr?: string;
  a_se?: string;
  a_ti?: string;
  a_tn?: string;
  a_tr?: string;
  a_va?: string;
  a_gl?: string;
  a_td?: string;
  as?: string;
  b1?: string;
  b2?: string;
  b3?: string;
  b4?: string;
  b5?: string;
  b6?: string;
  b9?: string;
  b12?: string;
  ba?: string;
  bi?: string;
  bk?: string;
  bo?: string;
  c?: string;
  ca?: string;
  cd?: string;
  cl?: string;
  co?: string;
  cr?: string;
  cu?: string;
  d?: string;
  e?: string;
  f?: string;
  h?: string;
  hg?: string;
  hol?: string;
  i?: string;
  k?: string;
  ka?: string;
  li?: string;
  mg?: string;
  mn?: string;
  mo?: string;
  na?: string;
  ni?: string;
  o3?: string;
  o6?: string;
  pb?: string;
  ph?: string;
  pp?: string;
  s?: string;
  sb?: string;
  se?: string;
  si?: string;
  sn?: string;
  v?: string;
  zn?: string;
  zr?: string;
  fat_min?: string;
  fat_pol?: string;
  fat_max?: string;
  fibres?: string;
  sugar?: string;
  carbs?: string;
  gi?: string;
  fe?: string;
  is_part1?: string;
  is_part2?: string;
  is_part3?: string;
  is_part4?: string;
  is_part5?: string;
  plus1?: string;
  plus2?: string;
};

export type TProductGuideData = { products: Product[] };
export interface TableProps {
  data: Product[];
  categories?: TFullProductCategories[];
  chunk?: number;
  filterData: any[] | undefined;
  setFilterData: any;
  isDropdownOpened: boolean;
  setIsDropdownOpened: (isOpened: boolean) => void;
  isCollapsed: boolean;
}

export type TGetProductsParams = {
  start: number;
  count?: number;
  filter: TFilter[] | null;
};

export type TCategory = {
  name: string;
  id: number;
  id_parent?: number;
  id_sex?: string;
  max_part: { 0: string[]; 1: string[] } | null;
  min_part: { 0: string[]; 1: string[] } | null;
  is_animal?: string;
  is_carb?: string;
  is_fat?: string;
  is_protein?: string;
  is_simple?: string;
  kmin?: string;
  kmax?: string;
  min?: string;
  max?: string;
};

export type TFullProductCategories = TCategory & {
  sub: TCategory[];
  deps?: TCategory[];
};

export type TProductCategories = TCategory;

export type TGetProductCategories = {
  deps: TProductCategories[];
};

export enum ProductCategoryFormat {
  Full,
  Structure,
}

export type TAddCategoryRequest = {
  name: string;
  id_parent: number;
};
