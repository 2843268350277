import { getFormData } from '../../helpers/formData';

type EditQueryRes = {
  url: string;
  data: FormData;
  method: string;
  headers: Record<string, string>;
};
/**

 A function that returns an object containing the necessary parameters to edit a resource.
 @function
 @param {string} url - The URL of the resource to edit.
 @param {Record<string, any>} params - The parameters necessary to edit the resource.
 @returns {EditQueryRes} An object containing the necessary parameters to edit a resource.
 @property {string} url - The URL of the edited resource.
 @property {string} method - The HTTP method used for the edit query.
 @property {FormData} data - The data sent in the edit query.
 @property {Object} headers - The headers sent in the edit query.
 */
export default (url: string) =>
  (params: Record<string, any>): EditQueryRes => {
    return {
      url,
      method: 'POST',
      data: getFormData(params),
      headers: { 'Content-Type': 'multipart/form-data' },
    };
  };
