import buildFormDataQuery from '../../utils/buildFormDataQuery';
import { TFeedingProteinsItem } from '../../../models/feeding';
import api from '../index';
import { TResult } from '../../../models/all';
import service from '../../../api/services/feeding.service';

export const feedingFatsApi = api.injectEndpoints({
  endpoints: builder => ({
    getFeedingFats: builder.query<TFeedingProteinsItem[], void>({
      query: () => '/sprav_fat_get/',
      transformResponse: (res): TFeedingProteinsItem[] =>
        service.proteinsGet(res.data.sprav_fat),
      providesTags: ['FeedingFats'],
    }),
    editFeedingFat: builder.mutation<
      TResult<{ sprav_protein: number }>,
      Record<string, string | number>
    >({
      query: buildFormDataQuery('/sprav_fat_edit/'),
      invalidatesTags: ['FeedingFats'],
    }),
    addFeedingFat: builder.mutation<
      TResult<{ sprav_fat: number }>,
      Record<string, string | number>
    >({
      query: buildFormDataQuery('/sprav_fat_add/'),
      invalidatesTags: ['FeedingFats'],
    }),
    deleteFeedingFat: builder.mutation<
      TResult<{ sprav_fat: number }>,
      { id_item: string | number }
    >({
      query: buildFormDataQuery('/sprav_fat_del/'),
      invalidatesTags: ['FeedingFats'],
    }),
  }),
});

export const {
  useGetFeedingFatsQuery,
  useAddFeedingFatMutation,
  useEditFeedingFatMutation,
  useDeleteFeedingFatMutation,
} = feedingFatsApi;
