export enum Routes {
  Default = '/',
  Login = '/login',
  ProductGuide = '/product-guide',
  ProductsDel = '/product-del',
  NutritionalStandards = '/nutritional-standards',
  CategoryTree = '/category-tree',
  GlycemicIndex = '/glycemic-index',
  Vitamins = '/vitamins',
  PortionSize = '/portion-size',
  FeedingCount = '/feeding-count',
  FeedingDensity = '/feeding-density',
  FeedingInterval = '/feeding-interval',
  FeedingDiets = '/feeding-diets',
  FeedingPercentage = '/feeding-percentage',
  FeedingWeekProducts = '/feeding-week-products',
  Dops = '/dops',
  Activities = '/activities',
  ActivitiesGroups = '/activities-groups',
  Professions = '/professions',
  Analyses = '/analyses',
  Questions = '/questions',
  TechCardsOld = '/tech-cards-old',
  TechCards = '/tech-cards',
  TechCardsDel = '/tech-cards/del',
  TechCardsEdit = '/tech-cards/edit',
  TechCardsDeps = '/tech-cards/deps',
  Notes = '/notes',
  Diseases = '/diseases',
  BodyWeight = '/body-weight',
  WaistHipRatio = '/waist-hip-ratio',
  Formulas = '/formulas',
  BodyTypes = '/body-types',
  Countries = '/countries',
  Clinics = '/clinics',
  ClinicsModal = '/clinics-modal',
  HealthSystems = '/health-systems',
  HealthSubSystems = '/health-subsystems',
}

export enum Params {
  Gender = ':gender',
  InitialFilter = ':initialFilter',
  ProductId = ':productId',
  Label = ':label',
  Id = ':id',
  Page = ':page',
  TechCardsType = ':techCardsType',
}
