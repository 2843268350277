import React, { FC, ReactNode, useMemo, useState, createContext } from 'react';
import { AlertContextProps, AlertStatus, Ttext } from 'models/alert';
import { initFunc } from 'helpers/initFunction';

const initText = {
  headline: '',
  body: '',
};

const ALERT_TIME = 3000;

export const AlertContext = createContext<AlertContextProps>({
  alertIsOpen: false,
  setAlert: initFunc,
  type: AlertStatus.Default,
  text: initText,
});

export const AlertProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const [alertIsOpen, setAlertIsOpen] = useState(false);

  const [text, setTextState] = useState<Ttext>(initText);

  const [type, setAlertType] = useState(AlertStatus.Default);

  const setAlert = (t: AlertStatus, data: Ttext) => {
    setAlertType(t);
    setTextState(data);
    setAlertIsOpen(s => !s);
    setTimeout(() => setAlertIsOpen(s => !s), ALERT_TIME);
  };

  const value = useMemo(() => {
    return { alertIsOpen, setAlert, type, text };
  }, [alertIsOpen, setAlert, type, text]);

  return (
    <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
  );
};
