import { createSlice } from '@reduxjs/toolkit';
import { RootStore } from '../types';
import { formulasApi } from 'store/services/formulas';

interface FormulasState {
  items: any[];
}

const initialState: FormulasState = {
  items: [],
};
const { getFormulas } = formulasApi.endpoints;

const slice = createSlice({
  name: 'formulas',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder.addMatcher(
      getFormulas.matchFulfilled,
      (state: any, { payload }) => {
        state.items = payload;
      },
    ),
});

export const selectFormulas = (state: RootStore): any[] => state.formulas.items;

export const { reducer: formulasReducer, actions: formulasActions } = slice;
