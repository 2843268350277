import { createSlice } from '@reduxjs/toolkit';
import { RootStore } from '../types';
import { productsApi } from '../services/productGuide';
import { Product } from '../../models/productGuide';

interface ProductGuideState {
  items: Product[];
}

const initialState: ProductGuideState = {
  items: [],
};
const { getProducts } = productsApi.endpoints;

const slice = createSlice({
  name: 'productGuide',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder.addMatcher(
      getProducts.matchFulfilled,
      (state: ProductGuideState, { payload }) => {
        state.items = payload.products;
      },
    ),
});

export const selectProductGuide = (state: RootStore): Product[] =>
  state.productGuide.items;

export const { reducer: productGuideReducer, actions: productGuideActions } =
  slice;
