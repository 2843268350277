import React, {
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useState,
  createContext,
  useCallback,
} from 'react';
import { useNavigation } from 'react-navi';
import { initFunc } from 'helpers/initFunction';
import { Routes } from 'constants/routes';

interface AuthContextProps {
  isAuthorized: boolean | null;
  setAuthorized: () => void;
}

export const AuthContext = createContext<AuthContextProps>({
  isAuthorized: null,
  setAuthorized: initFunc,
});

export const AuthProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null);
  const navigation = useNavigation();

  const checkAuthorization = (): boolean => {
    const local = localStorage.getItem('userData');
    const session = sessionStorage.getItem('userData');
    if (local !== null || session !== null) {
      return true;
    }
    return false;
  };

  const setAuthorized = useCallback(() => {
    const authorized = checkAuthorization();
    setIsAuthorized(authorized);
    if (!authorized) {
      navigation.navigate(Routes.Login);
    }
  }, [setIsAuthorized, navigation]);

  useEffect(() => {
    setAuthorized();
  }, [setAuthorized]);

  const value = useMemo(() => {
    return { isAuthorized, setAuthorized };
  }, [isAuthorized, setAuthorized]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
