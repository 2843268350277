import {
  TechCardsItem,
  TechCardsDataProduct,
  ServiceType,
  TechCardDdepType,
} from 'models/techCards';
import { Product } from 'models/productGuide';
import {
  aminoAcidsKeys,
  energyKeys,
  heavyMetals,
  macroKeys,
  microKeys,
  otherKeys,
  vitaminsKeys,
} from 'constants/nutrients';
import { dopsGet } from 'api/actions/dops';
import { arrayFilter } from 'helpers/arrayFilter';
import { TDdopProduct } from 'models/dops';
import { TFilter } from 'models/filter';
import { TObject } from 'models/all';
import { productGuide } from '../actions/productGuide';

type TechCardsItemInit = {
  id: string;
  name: string;
  text: string;
  is_del: string;
  is_green: string;
  col_items: string;
  col_problems: string;
  lost_vits: string;
  id_pdep1: string;
  id_pdep2: string;
  id_dep: string;
  id_dep2: string;
  image: string;
  is_animal: string;
  is_protein: string;
  is_fat: string;
  is_carb: string;
};

const capitalizeString = (string: string): string =>
  string.charAt(0).toUpperCase() + string.slice(1);

const getValue = (data: TechCardsItemInit[]): TechCardsItem[] => {
  return data.map(
    ({
      id,
      name,
      text,
      is_del,
      is_green,
      col_items,
      col_problems,
      id_pdep1,
      id_pdep2,
      id_dep,
      id_dep2,
      lost_vits,
      image,
      is_animal,
      is_protein,
      is_fat,
      is_carb,
    }) => ({
      id: Number(id),
      name,
      text,
      is_del: Boolean(Number(is_del)),
      is_green: Boolean(Number(is_green)),
      col_items: Number(col_items),
      col_problems: Number(col_problems),
      id_pdep1: id_pdep1,
      id_pdep2: id_pdep2,
      id_dep: Number(id_dep),
      id_dep2: Number(id_dep2),
      lost_vits: JSON.parse(lost_vits),
      image,
      is_animal,
      is_protein,
      is_fat,
      is_carb,
    }),
  );
};

const types = {
  Макроэлементы: macroKeys,
  Микроэлементы: microKeys,
  Витамины: vitaminsKeys,
  Аминокислоты: aminoAcidsKeys,
  'Тяж. металлы': heavyMetals,
  Другое: otherKeys,
};

const getItem = async (id: number[]): Promise<Product[]> =>
  await productGuide({
    start: 0,
    count: 0,
    filter: id.map(el => ({ param: 'id', value: el })),
  }).then(res => res.products);

const getDopItem = async (id: number[]): Promise<TDdopProduct[]> =>
  await dopsGet().then(res => arrayFilter(res, id));

const getProduct = async (
  data: TechCardsDataProduct[],
): Promise<ServiceType> => {
  const mainIds = data
    .filter(el => el.id_product !== null)
    .map(el => Number(el.id_product));

  const dopIds = data
    .filter(el => el.id_product2 !== null)
    .map(el => Number(el.id_product2));

  const products = await getItem(mainIds);

  const dops = await getDopItem(dopIds);

  const initialArr = data
    .map(({ id_product, id_product2, ...item }) => ({
      isMain: id_product !== null,
      id_product: Number(id_product || id_product2),
      ...item,
    }))
    .sort((a, b) =>
      a.id_product > b.id_product ? 1 : b.id_product > a.id_product ? -1 : 0,
    )
    .sort((a, b) => Number(b.isMain) - Number(a.isMain));

  const getNutrientValues = (
    id_product: number,
  ): {
    object: Record<string, unknown>;
    values: string;
    element: Product;
    valuesByType: Record<string, unknown>;
  } => {
    const currElement =
      products.filter(({ id }) => Number(id) === id_product)[0] || {};

    const notEmptyValues = Object.entries(currElement)
      .filter(([_, v]) => v !== null && Number(v) > 0)
      .reduce(
        (acc, [k, v]) => ({
          ...acc,
          [k]: v === Object(v) ? getNutrientValues(id_product) : v,
        }),
        {},
      ) as Product;

    const values = Object.entries(notEmptyValues)
      .filter(([k, _]) =>
        [...energyKeys, ...vitaminsKeys, ...macroKeys, ...microKeys].includes(
          k,
        ),
      )
      .reduce((acc, [k, v]) => acc + `${k}(${v}) `, '');

    const getNormilizeValues = ({
      arr,
      values = notEmptyValues,
    }: {
      arr: Array<string>;
      values?: Product;
    }) =>
      Object.entries(values)
        .filter(([k, _]) => arr.includes(k))
        .reduce((acc, [k, v]) => acc + capitalizeString(`${k}(${v}) `), '');

    const valuesByType = Object.fromEntries(
      Object.entries(types).map(([type, values]) => [
        type,
        getNormilizeValues({ arr: values }),
      ]),
    );

    return {
      object: notEmptyValues,
      values,
      element: currElement,
      valuesByType,
    };
  };

  const getString = (text: any): string =>
    Object.entries(text).reduce(
      (acc, [k, v]) => acc + capitalizeString(`${k}(${v}) `),
      '',
    );

  const getActualVits = (vits: Array<string>, allVits: any) =>
    Object.fromEntries(vits.map((vit: string) => [vit, allVits[vit]]));

  const getTypedVits = (vits: TObject<string>, defaultTypes = types) =>
    Object.entries(defaultTypes).reduce(
      (acc: TObject<string>, [typeName, typedArr]) => {
        acc[typeName] = Object.entries(vits)
          .filter(([k]) => typedArr.includes(k))
          .reduce((acc, [k, v]) => acc + capitalizeString(`${k}(${v}) `), '');
        return acc;
      },
      {} as TObject<string>,
    );

  const getDopName = (id: number) => {
    return dops.filter(el => Number(el.id) === id)[0]?.name || '';
  };

  const fullObjArr = initialArr.map(el => ({
    name: el.isMain
      ? String(getNutrientValues(el.id_product).element.label)
      : getDopName(el.id_product),
    values: el.isMain ? getNutrientValues(el.id_product).values : '',
    valuesByType: el.isMain
      ? getNutrientValues(el.id_product).valuesByType
      : {},
    col: Number(el.col),
    col2: Number(el.col2),
    id: Number(el.id),
    id_menu: Number(el.id_menu),
    isMain: el.isMain,
    id_product: Number(el.id_product),
    vits_all: getString(el.vits_all),
    vitsByType: getTypedVits(getActualVits(el.vits, el.vits_all)),
  }));

  return { data: fullObjArr, products };
};

const getDepValue = (data: TechCardDdepType[]): TechCardDdepType[] => {
  return data;
};

const getFilterParams = (filter: TFilter[] | null): Record<string, unknown> => {
  if (filter === null) return {};
  const getFilter = () => {
    if (!filter) return [];
    const id_dep: { key: string; value: string[] } = {
      key: 'id_dep',
      value: [],
    };
    const id_dep2: { key: string; value: string[] } = {
      key: 'id_dep2',
      value: [],
    };
    const param_name: { key: string; value: string | null } = {
      key: 'param_name',
      value: null,
    };

    filter.forEach(({ param, value }) => {
      switch (param) {
        case 'id_dep':
          return id_dep.value.push(String(value));
        case 'id_dep2':
          return id_dep2.value.push(String(value));
        case 'param_name':
          return (param_name.value = String(value));

        default:
          return;
      }
    });

    return {
      id_dep: id_dep.value,
      id_dep2: id_dep2.value,
      param_name: param_name.value,
    };
  };

  const clearFilter = Object.entries(getFilter())
    .filter(([_, v]) => v !== null && v.length !== 0)
    .reduce(
      (acc, [k, v]) => ({
        ...acc,
        [k]: v,
      }),
      {},
    );

  return clearFilter;
};

export default { getValue, getProduct, getDepValue, getFilterParams };
