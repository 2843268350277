import styled, { css, keyframes } from 'styled-components/macro';
import { theme } from '../../theme/index';
import { AlertStatus, ScAlertProps } from 'models/alert';
import emoji1 from 'assets/emoji/emoji1.svg';
import emoji2 from 'assets/emoji/emoji2.svg';

const show = keyframes`
  0%{
      opacity: 0;
      translate: 0 -100%;
  }
  100%{
      opacity: 1;
      translate: 0 0;
  }
`;
const hide = keyframes`
  0%{
      opacity: 1;
      translate: 0 0;
  }
  100%{
      opacity: 0;
      translate: 0 -100%;
  }
`;

export const ScAlert = styled.div<ScAlertProps>`
  opacity: 0;
  translate: 100% 0;
  padding: 8px 12px 8px 64px;
  box-shadow: ${theme.effect.tooltipShadow};
  border-radius: 10px;
  position: relative;
  margin-top: 16px;
  animation: ${show} 0.5s 0s ease forwards, ${hide} 0.5s 2s ease forwards;
  h3 {
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 9px;
    line-height: 160%;
  }

  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    max-width: 260px;
  }

  &:before {
    position: absolute;
    content: '';
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    border: 2px solid ${theme.color.white};
    border-radius: 50%;
  }

  ${({ type }) => {
    switch (type) {
      case AlertStatus.Error:
        return css`
          background-color: #ffe2e8;
          h3 {
            color: ${theme.color.error};
          }
          &:before {
            background: url(${emoji1}) center center no-repeat
              ${theme.color.error};
          }
        `;
      case AlertStatus.Success:
        return css`
          background-color: #e9faf2;
          h3 {
            color: ${theme.color.accent};
          }
          &:before {
            background: url(${emoji2}) center center no-repeat
              ${theme.color.accent};
          }
        `;
      default:
        return css`
          background-color: ${theme.color.white};
          padding-left: 12px;

          &:before {
            content: none;
          }
        `;
    }
  }}
`;

export const ScAlertContainer = styled.div<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 99999;

  @media (max-width: 965px) {
    left: calc(50% - (115px));
  }
`;
