import { createSlice } from '@reduxjs/toolkit';
import { RootStore } from '../../types';
import { TFeedingProteinsItem } from '../../../models/feeding';
import { feedingProteinApi } from '../../services/feeding/protein';

interface ProteinFeedingState {
  items: Array<TFeedingProteinsItem>;
}

const initialState: ProteinFeedingState = {
  items: [],
};

const {
  getFeedingProtein,
  // addFeedingInterval,
  // editFeedingInterval,
  // deleteFeedingInterval,
} = feedingProteinApi.endpoints;

const slice = createSlice({
  name: 'proteinFeeding',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder.addMatcher(
      getFeedingProtein.matchFulfilled,
      (state: ProteinFeedingState, { payload }) => {
        state.items = payload;
      },
    ),
});
export const selectFeedingProtein = (state: RootStore): [] =>
  state.proteinFeeding.items;

export const {
  reducer: feedingProteinReducer,
  actions: feedingProteinActions,
} = slice;
