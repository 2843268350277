import styled from 'styled-components/macro';

export const PageContainer = styled.div<{ isCollapsed: boolean }>`
  padding: 1.5rem;
  //max-height: calc(100% - 1rem);
  max-width: 100%;
  display: grid;
  position: relative;
  overflow: auto;

  @media (max-width: 965px) {
    overflow-y: ${({ isCollapsed }) => (isCollapsed ? 'auto' : 'hidden')};
  }

  @media (max-width: 850px) {
    padding: 1.5rem 0.8rem;
  }
`;
