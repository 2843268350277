import { ReactNode } from 'react';

export enum ButtonTheme {
  Primary = 'primary',
  Secondary = 'secondary',
  Outline = 'outline',
  Blue = 'blue',
  Transparent = 'transparent',
  TransparentMini = 'TransparentMini',
  Yellow = '#FFE380',
  Violet = '#E4E0F7',
  Green = '#E8F9F2',
  LightBlue = '#E1ECFE',
  Pink = '#FFE2E8',
  Sand = '#FFF3E9',
}

export interface ScButtonProps {
  disabled?: boolean;
  icon?: JSX.Element;
  color?: ButtonTheme;
  align?: 'center' | 'left' | 'right';
  minWidth?: string;
  borderRadius?: string;
}

export type ButtonType = 'reset' | 'button' | 'submit';

export interface ButtonProps extends ScButtonProps {
  children: ReactNode;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  className?: string;
  type?: ButtonType;
  id?: string;
  minWidth?: string;
}
