import api from './index';
import service from '../../api/services/techCards.services';
import { TCategoryTreeItem, TechCardDdepType } from '../../models/techCards';
import buildFormDataQuery from '../utils/buildFormDataQuery';

export const productDependencies = api.injectEndpoints({
  endpoints: builder => ({
    getProductDependency: builder.query<Array<TCategoryTreeItem>, void>({
      query: () => '/menus_deps_get/',
      transformResponse: res => res.data.menus_deps,
      providesTags: ['ProductDeps'],
    }),
    getProductSubdependency: builder.query<Array<TechCardDdepType>, void>({
      query: () => '/menus_deps2_get/',
      transformResponse: res => service.getDepValue(res.data.menus_deps2),
      providesTags: ['ProductDeps2'],
    }),
    getMenus: builder.query<Array<TechCardDdepType>, void>({
      query: () => '/menus_get/',
      transformResponse: res => res.data.menus,
      providesTags: ['ProductDeps2'],
    }),
    editProductDependency: builder.mutation({
      query: buildFormDataQuery('/menus_deps_edit/'),
      transformResponse: res => res.data,
      invalidatesTags: ['ProductDeps'],
    }),
    editProductSubDependency: builder.mutation({
      query: buildFormDataQuery('/menus_deps2_edit/'),
      transformResponse: res => res.data,
      invalidatesTags: ['ProductDeps2'],
    }),
    addProductDependency: builder.mutation({
      query: buildFormDataQuery('/menus_deps_add/'),
      transformResponse: res => res.data,
      invalidatesTags: ['ProductDeps'],
    }),
    addProductSubDependency: builder.mutation({
      query: buildFormDataQuery('/menus_deps2_add/'),
      transformResponse: res => res.data,
      invalidatesTags: ['ProductDeps2'],
    }),
    deleteProductDependency: builder.mutation({
      query: buildFormDataQuery('/menus_deps_del/'),
      transformResponse: res => res.data,
      invalidatesTags: ['ProductDeps'],
    }),
    deleteProductSubDependency: builder.mutation({
      query: buildFormDataQuery('/menus_deps2_del/'),
      transformResponse: res => res.data,
      invalidatesTags: ['ProductDeps2'],
    }),
  }),
});

export const {
  useGetProductDependencyQuery,
  useLazyGetProductSubdependencyQuery,
  useGetProductSubdependencyQuery,
  useLazyGetProductDependencyQuery,
  useAddProductDependencyMutation,
  useAddProductSubDependencyMutation,
  useEditProductDependencyMutation,
  useEditProductSubDependencyMutation,
  useDeleteProductDependencyMutation,
  useDeleteProductSubDependencyMutation,
  useGetMenusQuery,
} = productDependencies;
