import { createSlice } from '@reduxjs/toolkit';
import { feedingApi } from '../../services/feeding';
import { RootStore } from '../../types';
import { TFeedingWeekValue } from '../../../models/feeding';

interface WeekFeedingState {
  items: TFeedingWeekValue[];
}

const initialState: WeekFeedingState = {
  items: [],
};
const { getFeedingWeek } = feedingApi.endpoints;

const slice = createSlice({
  name: 'weekFeeding',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder.addMatcher(
      getFeedingWeek.matchFulfilled,
      (state: WeekFeedingState, { payload }) => {
        state.items = payload.values;
      },
    ),
});
export const selectWeekFeedings = (state: RootStore): [] =>
  state.weekFeeding.items;

export const { reducer: weekFeedingReducer, actions: weekFeedingActions } =
  slice;
