import { productCategoriesReducer } from './productCategories';
import { weekFeedingReducer } from './feeding';
import { densityFeedingReducer } from './feeding/density';
import { countFeedingReducer } from './feeding/count';
import { feedingProteinReducer } from './feeding/protein';
import { feedingFatsReducer } from './feeding/fats';
import { feedingCarbsReducer } from './feeding/carbs';
import { dopProductsReducer } from './dops';
import { analysesReducer } from './analyses';
import { questionsReducer } from './questions';
import { techCardsReducer } from './techCards';
import { productDependenciesReducer } from './productDependencies';
import { productGuideReducer } from './productGuide';
import { bodyWeightReducer } from './bodyWeight';
import { waistHipRatioReducer } from './waistHipRatio';
import { formulasReducer } from './formulas';

export default {
  productCategories: productCategoriesReducer,
  productGuide: productGuideReducer,
  weekFeeding: weekFeedingReducer,
  densityFeeding: densityFeedingReducer,
  countFeeding: countFeedingReducer,
  proteinFeeding: feedingProteinReducer,
  fatsFeeding: feedingFatsReducer,
  carbsFeeding: feedingCarbsReducer,
  dopProducts: dopProductsReducer,
  analyses: analysesReducer,
  questions: questionsReducer,
  techCards: techCardsReducer,
  productDependencies: productDependenciesReducer,
  bodyWeight: bodyWeightReducer,
  waistHipRatio: waistHipRatioReducer,
  formulas: formulasReducer,
};
