import { createSlice } from '@reduxjs/toolkit';
import { RootStore } from '../types';
import { bodyWeightApi } from 'store/services/bodyWeight';

interface BodyWeightState {
  items: any[];
}

const initialState: BodyWeightState = {
  items: [],
};
const { getBodyWeights } = bodyWeightApi.endpoints;

const slice = createSlice({
  name: 'bodyWeight',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder.addMatcher(
      getBodyWeights.matchFulfilled,
      (state: any, { payload }) => {
        state.items = payload;
      },
    ),
});

export const selectBodyWeight = (state: RootStore): any[] =>
  state.bodyWeight.items;

export const { reducer: bodyWeightReducer, actions: bodyWeightActions } = slice;
