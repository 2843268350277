import buildFormDataQuery from '../../utils/buildFormDataQuery';
import { TFeedingProteinsItem } from '../../../models/feeding';
import api from '../index';
import { TResult } from '../../../models/all';
import service from '../../../api/services/feeding.service';

export const feedingCarbsApi = api.injectEndpoints({
  endpoints: builder => ({
    getFeedingCarbs: builder.query<TFeedingProteinsItem[], void>({
      query: () => '/sprav_carb_get/',
      transformResponse: (res): TFeedingProteinsItem[] =>
        service.proteinsGet(res.data.sprav_carb),
      providesTags: ['FeedingCarbs'],
    }),
    editFeedingCarb: builder.mutation<
      TResult<{ sprav_protein: number }>,
      Record<string, string | number>
    >({
      query: buildFormDataQuery('/sprav_carb_edit/'),
      invalidatesTags: ['FeedingCarbs'],
    }),
    addFeedingCarb: builder.mutation<
      TResult<{ sprav_carb: number }>,
      Record<string, string | number>
    >({
      query: buildFormDataQuery('/sprav_carb_add/'),
      invalidatesTags: ['FeedingCarbs'],
    }),
    deleteFeedingCarb: builder.mutation<
      TResult<{ sprav_carb: number }>,
      { id_item: string | number }
    >({
      query: buildFormDataQuery('/sprav_carb_del/'),
      invalidatesTags: ['FeedingCarbs'],
    }),
  }),
});

export const {
  useGetFeedingCarbsQuery,
  useAddFeedingCarbMutation,
  useEditFeedingCarbMutation,
  useDeleteFeedingCarbMutation,
} = feedingCarbsApi;
