import React, { FC, RefObject, useContext, useEffect, useState } from 'react';
import { INavItems, navItems } from 'mocks/navbar';
import { NavItem } from './NavItem';
import { ReactComponent as Logout } from 'assets/Icons/logout.svg';
import { ReactComponent as CollapseArrow } from 'assets/Icons/chevron.svg';
import { ScNavbar } from './styled';
import { Logo } from '../Logo';
import { Button } from '../Button';
import { AuthContext } from '../../context/auth';
import auth from '../../api/services/auth.service';
import { useCurrentRoute, useNavigation } from 'react-navi';
import { usePrevious } from '../../hooks/usePrevious';
import isEqualObj from '../../helpers/isEqualObj';
import { NavbarCollapseContext } from 'context/navbarCollapse';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { Routes } from 'constants/routes';

export const Navbar: FC = () => {
  const { isAuthorized, setAuthorized } = useContext(AuthContext);
  const { isCollapsed, setIsCollapsed } = useContext(NavbarCollapseContext);

  const [activeItem, setActiveItem] = useState<INavItems | undefined>();
  const prevActiveItem = usePrevious(activeItem);
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  const { url } = useCurrentRoute();
  const { navigate } = useNavigation();
  const handleLogout = async () => {
    await auth.logout().then(() => setAuthorized());
  };

  const handleOpen = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const item = navItems.find(
      item => item.name === e.currentTarget.textContent,
    );
    if (item && item.linkTo) {
      navigate(item.linkTo);
    } else {
      setActiveItem(item);
    }
  };

  const findNavItemByPath = (
    arr: Array<INavItems>,
    pathname: string,
  ): INavItems | undefined => {
    return arr.find(
      item =>
        item.linkTo?.split('/').includes(pathname) ||
        (item.children && findNavItemByPath(item.children, pathname)),
    );
  };

  const getActiveNavFromUrl = (arr: Array<INavItems>, pathname: string) => {
    const item = findNavItemByPath(arr, pathname);

    if (!item) {
      return arr.find(item =>
        item.children?.some(child => findNavItemByPath([child], pathname)),
      );
    }

    return item;
  };

  useEffect(() => {
    if (!url.pathname) return;
    const firstPathname = url.pathname.split('/')[1];
    const active = getActiveNavFromUrl(navItems, firstPathname);
    if (prevActiveItem && active && isEqualObj(prevActiveItem, active)) return;
    setActiveItem(active);
    if (url.pathname === Routes.ClinicsModal) {
      setActiveItem(navItems[5]);
    }
  }, [url]);

  useEffect(() => {
    if (screenWidth < 965) {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);
    }
  }, [screenWidth]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const outsideClick = (e: MouseEvent) => {
    if (!isCollapsed && window.innerWidth < 965) {
      setIsCollapsed(true);
    }
  };
  const ref: RefObject<HTMLDivElement> = useOutsideClick(outsideClick);

  return (
    <ScNavbar isCollapsed={isCollapsed} ref={ref}>
      <div className="navbar">
        <Logo isCollapsed={isCollapsed} />
        <Button
          className="collapsed-toggle"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          <CollapseArrow
            style={{
              transition: 'all 0.5s ease',
              transform: isCollapsed ? 'rotate(-90deg)' : 'rotate(90deg)',
              marginLeft: '3px',
            }}
          />
        </Button>
        {navItems.map((item, index) => (
          <NavItem
            {...item}
            isOpen={item.name === activeItem?.name}
            key={`${index}.${item.name}`}
            onClick={handleOpen}
          />
        ))}
      </div>
      {isCollapsed && isAuthorized ? (
        <Button onClick={handleLogout}>
          <Logout />
        </Button>
      ) : (
        <Button icon={<Logout />} onClick={handleLogout}>
          Выйти из аккаунта
        </Button>
      )}
    </ScNavbar>
  );
};
