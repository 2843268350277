import React, { useState, FC, ReactNode, useMemo, createContext } from 'react';
import { initFunc } from 'helpers/initFunction';
import { NavbarCollapseContextProps } from 'models/collapse';

export const NavbarCollapseContext = createContext<NavbarCollapseContextProps>({
  isCollapsed: false,
  setIsCollapsed: initFunc,
});

export const NavbarCollapseProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const value = useMemo(() => {
    return {
      isCollapsed,
      setIsCollapsed,
    };
  }, [isCollapsed, setIsCollapsed]);

  return (
    <NavbarCollapseContext.Provider value={value}>
      {children}
    </NavbarCollapseContext.Provider>
  );
};
