import React from 'react';
import { createRoot } from 'react-dom/client';
import { Router } from 'react-navi';
import HelmetProvider from 'react-navi-helmet-async';
import reportWebVitals from './reportWebVitals';

import { routes } from 'modules/routes';
import ErrorBoundary from 'modules/errors/ErrorBoundary';

import App from 'App';
import { Provider } from 'react-redux';
import { store } from './store';

const container = document.getElementById('root');
const root = createRoot(container as Element);

root.render(
  <ErrorBoundary>
    <HelmetProvider>
      <Provider store={store}>
        <Router routes={routes}>
          <App />
        </Router>
      </Provider>
    </HelmetProvider>
  </ErrorBoundary>,
);

reportWebVitals();
