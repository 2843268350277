import React, { FC } from 'react';
import { useNavigation } from 'react-navi';

import { ButtonProps } from 'models/button';
import { ScButton } from './styled';

import { ReactComponent as Arrow } from 'assets/Icons/arrow.svg';
import { Routes } from 'constants/routes';

export const Button: FC<ButtonProps> = ({
  children,
  color,
  onClick,
  disabled,
  icon,
  className,
  id,
  type = 'button',
  align,
  minWidth,
  borderRadius,
}) => {
  return (
    <ScButton
      minWidth={minWidth}
      borderRadius={borderRadius}
      align={align}
      onClick={onClick}
      color={color}
      disabled={disabled}
      icon={icon}
      className={className}
      type={type}
      id={id}
    >
      {icon}
      {children}
    </ScButton>
  );
};

interface GoBackButton {
  goTo?: string;
  callback?: () => void;
}

export const GoBackButton: FC<GoBackButton> = ({ goTo, callback }) => {
  const { navigate } = useNavigation();

  return (
    <Button
      className={'goBack'}
      onClick={() => {
        navigate(goTo ?? Routes.Default);
        callback && callback();
      }}
    >
      <Arrow />
    </Button>
  );
};
