import { createApi } from '@reduxjs/toolkit/query/react';
import axios from 'axios';

//FIXME: use .env
const baseApiUrl = '/api/admin/';

export const axiosApi = axios.create({
  baseURL: baseApiUrl,
  headers: {
    'content-type': 'application/json',
    accept: 'application/json',
  },
});

const axiosBaseQuery = async (requestOpts: any = {}) => {
  try {
    const result = await axiosApi(requestOpts);
    return { data: result.data };
  } catch (err: any) {
    return err;
  }
};

const api = createApi({
  reducerPath: 'splitApi',
  baseQuery: axiosBaseQuery,
  tagTypes: [
    'Auth',
    'Nutrients',
    'Vitamins',
    'ProductCategories',
    'Feeding',
    'FeedingDensity',
    'FeedingCount',
    'FeedingInterval',
    'FeedingDiet',
    'FeedingProtein',
    'FeedingFats',
    'FeedingCarbs',
    'DopProducts',
    'Products',
    'GlycemicIndex',
    'Activities',
    'Professions',
    'Analyses',
    'Disease',
    'Questions',
    'Notes',
    'TechCards',
    'Product',
    'ProductDeps',
    'ProductDeps2',
    'Clinics',
    'HealthSystems',
    'BodyWeights',
    'WaistHipRatio',
    'Formulas',
    'BodyTypes',
    'Countries',
    'ActivitiesGroups',
  ],
  endpoints: () => ({}),
});

export default api;
