import React, {
  FC,
  ReactNode,
  useMemo,
  useState,
  createContext,
  useEffect,
} from 'react';
import { initFunc } from 'helpers/initFunction';
import {
  FILTER_STORAGE_NAMES,
  FilterContextProps,
  TFilter,
} from 'models/filter';

export const FilterContext = createContext<FilterContextProps>({
  setFilter: initFunc,
  removeFilterItem: initFunc,
  removeFilter: initFunc,
  editFilter: initFunc,
  filter: null,
});

export const FilterProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const [filter, setFilterValue] = useState<TFilter[] | null>(null);

  useEffect(() => {
    const initValue = localStorage.getItem(FILTER_STORAGE_NAMES.DEFAULT);
    if (!initValue) return;
    setFilterValue(JSON.parse(initValue));
  }, []);

  const removeFilterItem = (param?: TFilter[]) => {
    if (!param || !filter) {
      return;
    }

    const set = new Set(param.map(paramItem => JSON.stringify(paramItem)));

    const newData = filter.filter(el => !set.has(JSON.stringify(el)));

    if (newData.length === 0) {
      localStorage.removeItem(FILTER_STORAGE_NAMES.DEFAULT);
    } else {
      localStorage.setItem(
        FILTER_STORAGE_NAMES.DEFAULT,
        JSON.stringify(newData),
      );
    }

    if (filter !== null || newData.length !== 0) {
      setFilterValue(newData);
    }
  };

  const removeFilter = () => {
    setFilterValue(null);
    localStorage.removeItem(FILTER_STORAGE_NAMES.DEFAULT);
  };

  const setFilter = (param?: TFilter[]) => {
    const localStorageItem = localStorage.getItem(FILTER_STORAGE_NAMES.DEFAULT);
    const filter: TFilter[] | null = localStorageItem
      ? JSON.parse(localStorageItem)
      : null;

    if (!param) return;

    const newFilter =
      Array.isArray(filter) && Array.isArray(param)
        ? [...filter, ...param]
        : param;

    setFilterValue(newFilter);
    localStorage.setItem(
      FILTER_STORAGE_NAMES.DEFAULT,
      JSON.stringify(newFilter),
    );
  };

  const editFilter = (param?: TFilter) => {
    if (!param) return;

    if (!filter) {
      setFilter([param]);
      return;
    }

    const index = filter.findIndex(el => el.param === param.param);

    const newArr =
      index === -1
        ? [...filter, param]
        : filter.map((el, i) => (i === index ? param : el));

    setFilterValue(newArr);

    const newArrString = JSON.stringify(newArr);
    const filterString = JSON.stringify(filter);

    if (newArrString !== filterString) {
      localStorage.setItem(FILTER_STORAGE_NAMES.DEFAULT, newArrString);
    }
  };

  const value = useMemo(() => {
    return {
      filter,
      setFilter,
      removeFilterItem,
      removeFilter,
      editFilter,
    };
  }, [filter, setFilter, removeFilterItem, removeFilter, editFilter]);

  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  );
};
