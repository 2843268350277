import { ButtonTheme } from 'models/button';

export enum ProductParams {
  Calories = 'Ккал',
  Proteins = 'Белки',
  Fats = 'Жиры',
  Сarbohydrates = 'Углеводы',
  GlycemicIndex = 'GI',
}

export enum Vitamins {
  A = 'A РЭ',
  Bk = 'Бета Каротин',
  B1 = 'B1 тиамин',
  B2 = 'B2 рибофлавин',
  B4 = 'B4 холин',
  B5 = 'B5 пантотеновая',
  B6 = 'B6 пиридоксин',
  B9 = 'B9 фолаты',
  B12 = 'B12 кобаламин',
  C = 'C аскорбиновая',
  D = 'D кальциферол',
  E = 'E альфа токоферол, ТЭ',
  H = 'H биотин',
  Ka = 'K филлохинон',
  Pp = 'PP НЭ',
  B3 = 'B3',
}

export enum Micronutrients {
  K = 'Калий',
  Ca = 'Кальций',
  Mg = 'Магний',
  Na = 'Натрий',
  S = 'Сера',
  Ph = 'Фосфор',
  Cl = 'Хлор',
}

export enum Macronutrients {
  Si = 'Кремний',
  Fe = 'Железо',
  I = 'Йод',
  Co = 'Кобальт',
  Mn = 'Марганец',
  Cu = 'Медь',
  Mo = 'Молибден',
  Se = 'Селен',
  F = 'Фтор',
  Cr = 'Хром',
  Zn = 'Цинк',
  V = 'Ванадий',
  Li = 'Литий',
  Ni = 'Никель',
  Bo = 'Бор',
}

export enum HeavyMetals {
  Ba = 'Барий',
  Pb = 'Свинец',
  As = 'Мышьяк',
  Hg = 'Ртуть',
  Cd = 'Кадмий',
  Sb = 'Сурьма',
  Zr = 'Цирконий',
  Sn = 'Олово',
  Bi = 'Висмут',
}

export enum AminoAcidsNew {
  Thr = 'Триптофан',
  Trp = 'Треонин',
  Ile = 'Изолейцин',
  Leu = 'Лейцин',
  Lys = 'Лизин',
  Met = 'Метионин',
  Cys = 'Цистеин',
  Phe = 'Фенилаланин',
  Tyr = 'Тирозин',
  Val = 'Валин',
  Arg = 'Аргинин',
  His = 'Гистидин',
  Ala = 'Аланин',
  Asn = 'Аспарагиновая',
  Glu = 'Глутаминовая',
  Gly = 'Глицин',
  Pro = 'Пролин',
  Ser = 'Серин',
}

export enum AminoAcids {
  Tn = 'Триптофан',
  Tr = 'Треонин',
  Iz = 'Изолейцин',
  Le = 'Лейцин',
  Li = 'Лизин',
  Me = 'Метионин',
  Ce = 'Цистеин',
  Fe = 'Фенилаланин',
  Ti = 'Тирозин',
  Va = 'Валин',
  Ar = 'Аргинин',
  Gi = 'Гистидин',
  Al = 'Аланин',
  As = 'Аспарагиновая',
  Gl = 'Глутаминовая',
  Gc = 'Глицин',
  Pr = 'Пролин',
  Se = 'Серин',
}

export const AminoAcidsTechNames = {
  Tn: 'Thr',
  Tr: 'Trp',
  Iz: 'Ile',
  Le: 'Leu',
  Li: 'Lys',
  Me: 'Met',
  Ce: 'Cys',
  Fe: 'Phe',
  Ti: 'Tyr',
  Va: 'Val',
  Ar: 'Arg',
  Gi: 'His',
  Al: 'Ala',
  As: 'Asn',
  Gl: 'Glu',
  Gc: 'Gly',
  Pr: 'Pro',
  Se: 'Ser',
};

export enum OtherNew {
  Sugar = 'Моно- и дисахариды cахара',
  Chol = 'Холестерин',
  'n-3' = 'Омега-3 жирные кислоты',
  'n-6' = 'Омега-6 жирные кислоты',
  SFA = 'Мононенасыщ. кислоты',
  MUFA = 'Насыщеные кислоты',
  PUFA = 'Полиненасыщ. кислоты',
  Fibres = 'Пищевые волокна',
}

export enum Other {
  Sugar = 'Моно- и дисахариды cахара',
  Hol = 'Холестерин',
  O3 = 'Омега-3 жирные кислоты',
  O6 = 'Омега-6 жирные кислоты',
  'Fat.max' = 'Мононенасыщ. кислоты',
  'Fat.min' = 'Насыщеные кислоты',
  'Fat.pol' = 'Полиненасыщ. кислоты',
  Fibres = 'Пищевые волокна',
}

export const OtherTechNames = {
  Sugar: 'Sugar',
  Hol: 'Chol',
  O3: 'n-3',
  O6: 'n-6',
  'Fat.max': 'SFA',
  'Fat.min': 'MUFA',
  'Fat.pol': 'PUFA',
  Fibres: 'Fibres',
};

export const energyKeys: string[] = ['calories', 'proteins', 'fats', 'carbs'];
export const ruEnergyKeys: string[] = ['Калории', 'Белки', 'Жиры', 'Углеводы'];

export const aminoAcidsKeys: string[] = [
  // 'a',
  'a_va',
  'a_iz',
  'a_le',
  'a_li',
  'a_me',
  'a_tr',
  'a_fe',
  'a_tn',
  'a_gi',
  'a_ce',
  'a_ti',
  'a_ar',
  'a_al',
  'a_as',
  'a_gl',
  // 'a_dc',
  'a_pr',
  'a_se',
  'a_gc',
  // 'a_td',
];
export const vitaminsKeys: string[] = [
  'bk',
  'b1',
  'b2',
  'b3',
  'b4',
  'b5',
  'b6',
  'b9',
  'b12',
  'c',
  'd',
  'e',
  'h',
  'k',
  'pp',
];

export const macroKeys: string[] = [
  'c',
  'si',
  'fe',
  'i',
  'co',
  'mn',
  'cu',
  'mo',
  'f',
  'cr',
  'zn',
  'v',
  'li',
  'ni',
  'bo',
];

export const heavyMetals: string[] = [
  'ba',
  'pb',
  'as',
  'hg',
  'cd',
  'sb',
  'zr',
  'sn',
  'bi',
];
export const microKeys: string[] = ['ka', 'ca', 'mg', 'na', 's', 'ph', 'cl'];

export const otherKeys: string[] = [
  'sugar',
  'hol',
  'o3',
  'o6',
  'fat_mix',
  'fat_max',
  'fat_pol',
  'fibres',
];

export const fatSoluble: string[] = ['bk', 'd', 'a', 'e', 'k'];

export const waterSoluble: string[] = [
  'b3',
  'c',
  'b1',
  'b12',
  'b2',
  'b4',
  'b5',
  'b6',
  'b9',
  'h',
  'pp',
];

export const elementsByType = [
  {
    id: 0,
    title: 'Макроэлементы',
    items: macroKeys,
    color: ButtonTheme.Violet,
  },
  {
    id: 1,
    title: 'Микроэлементы',
    items: microKeys,
    color: ButtonTheme.Yellow,
  },
  {
    id: 2,
    title: 'Тяжелые металлы',
    items: heavyMetals,
    color: ButtonTheme.Green,
  },
  {
    id: 3,
    title: 'Витамины',
    items: [],
    color: ButtonTheme.LightBlue,
  },
  {
    id: 4,
    title: 'Аминокислоты',
    items: aminoAcidsKeys,
    color: ButtonTheme.Pink,
  },
  { id: 5, title: 'Другое', items: otherKeys, color: ButtonTheme.Sand },
];

export const subVitamins = [
  {
    id: 0,
    title: 'Жирорастворимые',
    items: fatSoluble,
    color: ButtonTheme.LightBlue,
  },
  {
    id: 1,
    title: 'Водорастворимые',
    items: waterSoluble,
    color: ButtonTheme.LightBlue,
  },
];

/////////// Белки 1 ////////
export const meetChildrenTabs = [
  { id: 8, slug: '8', title: 'Красное мясо', items: [] },
  { id: 7, slug: '7', title: 'Птица', items: [] },
  { id: 24, slug: '24', title: 'Яйца', items: [] },
  { id: 51, slug: '51', title: 'Субпродукты', items: [] },
];

export const fishChildrenTabs = [
  { id: 36, slug: '36', title: 'Жирная', items: [] },
  { id: 37, slug: '37', title: 'Нежирная', items: [] },
  { id: 56, slug: '56', title: 'Моллюски', items: [] },
  { id: 66, slug: '66', title: 'Морепродукты', items: [] },
];

export const dairyChildrenTabs = [
  { id: 16, slug: '16', title: 'Кисломолочные продукты', items: [] },
  { id: 43, slug: '43', title: 'Масло', items: [] },
  { id: 44, slug: '44', title: 'Молоко', items: [] },
  { id: 45, slug: '45', title: 'Сыры жирные (более 30%)', items: [] },
  { id: 46, slug: '46', title: 'Сыры нежирные (менее 30%)', items: [] },
];

export const animalProteinChildrenTabs = [
  { id: 0, slug: '1', title: 'Мясо', index: 1, items: meetChildrenTabs },
  { id: 1, slug: '35', title: 'Рыба', index: 2, items: fishChildrenTabs },
];
///////////

/////////// Белки 2 ////////
export const legumesChildrenTabs = [
  { id: 27, slug: '27', title: 'Бобовые', items: [] },
];

export const mushroomsChildrenTabs = [
  { id: 13, slug: '13', title: 'Лесные', items: [] },
];

export const additionalProdsChildrenTabs = [
  { id: 54, slug: '54', title: 'Доп. продукты', items: [] },
  { id: 48, slug: '48', title: 'Водоросли', items: [] },
];

export const vegetableProteinChildrenTabs = [
  { id: 0, slug: '26', title: 'Бобовые', items: legumesChildrenTabs },
  { id: 1, slug: '2', title: 'Овощи', items: [], depId: 10 },
  {
    id: 2,
    slug: '2',
    title: 'Доп. Продукты',
    items: additionalProdsChildrenTabs,
  },
];
/////////

/////////// Жиры 1 ////////
export const meetFatsChildrenTabs = [
  { id: 51, slug: '51', title: 'Субпродукты', items: [] },
];

export const fishFatsChildrenTabs = [
  { id: 36, slug: '36', title: 'Субпродукты', items: [] },
];

export const milkFatsChildrenTabs = [
  { id: 16, slug: '16', title: 'Кисломолочные продукты', items: [] },
  { id: 43, slug: '43', title: 'Масло', items: [] },
  { id: 44, slug: '44', title: 'Молоко', items: [] },
  { id: 45, slug: '45', title: 'Сыры жирные (более 30%)', items: [] },
  { id: 46, slug: '46', title: 'Сыры нежирные (менее 30%)', items: [] },
];

export const animalFatsChildrenTabs = [
  {
    id: 2,
    slug: '3',
    title: 'Молочная продукция',
    index: 3,
    items: milkFatsChildrenTabs,
  },
  {
    id: 0,
    slug: '1',
    title: 'Мясные субпродукты',
    index: 1,
    items: meetFatsChildrenTabs,
  },
  {
    id: 1,
    slug: '35',
    title: 'Рыбные субпродукты',
    index: 2,
    items: fishFatsChildrenTabs,
  },
];
///////////

/////////// Жиры 2 ////////
export const seedsChildrenTabs = [
  { id: 25, slug: '25', title: 'Орехи', items: [] },
  { id: 25, slug: '25', title: 'Семена', items: [] },
];

export const vegetableFatsChildrenTabs = [
  {
    id: 0,
    slug: '5',
    title: 'Масла растительные',
    index: 1,
    items: [],
    depId: 17,
  },
  {
    id: 1,
    slug: '5',
    title: 'Орехи и семена',
    index: 2,
    items: seedsChildrenTabs,
  },
  { id: 2, slug: '5', title: 'Овощи жирные', index: 3, items: [], depId: 39 },
];
///////////

/////////// Углеводы 1 ////////
export const breadPastaChildrenTabs = [
  { id: 63, slug: '63', title: 'Макаронные', items: [] },
  { id: 52, slug: '52', title: 'Хлеб, выпечка', items: [] },
];

export const greensChildrenTabs = [
  { id: 10, slug: '10', title: 'Овощи', items: [] },
  { id: 11, slug: '11', title: 'Зелень', items: [] },
];

export const additionalCarbonChildrenTabs = [
  { id: 54, slug: '54', title: 'Доп. продукты', items: [] },
  { id: 53, slug: '53', title: 'Приправы и специи', items: [] },
  { id: 62, slug: '62', title: 'Алкогольные напитки', items: [] },
  { id: 61, slug: '61', title: 'Безалкогольные напитки', items: [] },
  { id: 60, slug: '60', title: 'Соки, нектары, морсы', items: [] },
  { id: 48, slug: '48', title: 'Водоросли', items: [] },
];

export const simpleCarbonChildrenTabs = [
  {
    id: 0,
    slug: '28',
    title: 'Макаронные и выпечка',
    index: 1,
    items: breadPastaChildrenTabs,
  },
  {
    id: 2,
    slug: '2',
    title: 'Доп. Продукты',
    index: 3,
    items: additionalCarbonChildrenTabs,
  },
];
///////////

/////////// Углеводы 2 ////////
export const cerealsChildrenTabs = [
  { id: 29, slug: '29', title: 'Крупы', items: [] },
  { id: 52, slug: '52', title: 'Хлеб, выпечка', items: [] },
];

export const fruitsChildrenTabs = [
  { id: 10, slug: '10', title: 'Овощи', items: [] },
  { id: 19, slug: '19', title: 'Фрукты (кислые)', items: [] },
  { id: 32, slug: '32', title: 'Фрукты (сладкие)', items: [] },
  { id: 33, slug: '33', title: 'Ягоды (сладкие)', items: [] },
  { id: 38, slug: '38', title: 'Ягоды (кислые)', items: [] },
  { id: 11, slug: '11', title: 'Зелень', items: [] },
  { id: 48, slug: '48', title: 'Водоросли', items: [] },
];

export const additionalHardCarbonChildrenTabs = [
  { id: 65, slug: '65', title: 'Доп. продукты', items: [] },
  { id: 53, slug: '53', title: 'Приправы и специи', items: [] },
  { id: 60, slug: '60', title: 'Напитки', items: [] },
];

export const hardCarbonChildrenTabs = [
  {
    id: 0,
    slug: '28',
    title: 'Крупы',
    index: 1,
    items: cerealsChildrenTabs,
  },
  {
    id: 1,
    slug: '4',
    title: 'Овощи и фрукты',
    index: 2,
    items: fruitsChildrenTabs,
  },
  {
    id: 2,
    slug: '2',
    title: 'Доп. Продукты',
    index: 3,
    items: additionalHardCarbonChildrenTabs,
  },
];
///////////

export const proteinsType = [
  {
    id: 0,
    slug: '1',
    title: 'Белки животные',
    label: 'Белки животные',
    items: animalProteinChildrenTabs,
    is_animal: '1',
    is_simple: '0',
  },
  {
    id: 1,
    slug: '0',
    title: 'Белки растительные',
    label: 'Белки растительные',
    items: vegetableProteinChildrenTabs,
    is_animal: '0',
    is_simple: '0',
  },
];

export const fatsType = [
  {
    id: 0,
    slug: '1',
    title: 'Жиры животные',
    label: 'Жиры животные',
    items: animalFatsChildrenTabs,
    is_animal: '1',
    is_simple: '0',
  },
  {
    id: 1,
    slug: '0',
    title: 'Жиры растительные',
    label: 'Жиры растительные',
    items: vegetableFatsChildrenTabs,
    is_animal: '0',
    is_simple: '0',
  },
];

export const carbonsType = [
  {
    id: 0,
    slug: '1',
    title: 'Простые',
    label: 'Простые',
    items: simpleCarbonChildrenTabs,
    is_animal: '0',
    is_simple: '1',
  },
  {
    id: 1,
    slug: '0',
    title: 'Сложные',
    label: 'Сложные',
    items: hardCarbonChildrenTabs,
    is_animal: '0',
    is_simple: '0',
  },
];
///////////////

export const feedingNameSelect = [
  'Завтрак',
  'Ланч',
  'Обед',
  'Полудник',
  'Ужин',
];

export const nutrientsSelect = ['Белки', 'Жиры', 'Углеводы'];
