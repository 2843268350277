import React, { FC, ReactNode, useContext, useEffect } from 'react';
import { useNavigation } from 'react-navi';
import { AuthContext } from 'context/auth';
import {
  ScDefaulContainer,
  ScMobileNavbarWrapper,
  ScMobilefFoatingNavbar,
  ScPageWrapper,
} from 'styles';
import { Navbar } from 'components/Navbar';
import { PageContainer } from 'components/PageContainer/styles';
import { Alert } from 'components/Alert';
import { Routes } from 'constants/routes';
import { Modal } from 'components/Modal';
import { ModalContext } from 'context/modal';
import { NavbarCollapseContext } from 'context/navbarCollapse';

export const DefaultLayout: FC<{ children?: ReactNode }> = ({ children }) => {
  const { isAuthorized } = useContext(AuthContext);
  const { isCollapsed } = useContext(NavbarCollapseContext);
  const { isOpen } = useContext(ModalContext);
  const { navigate } = useNavigation();

  useEffect(() => {
    if (isAuthorized === null) return;
    if (!isAuthorized) {
      navigate(Routes.Login);
    }
  }, [isAuthorized]);

  return (
    <ScPageWrapper isOpen={isOpen}>
      <ScDefaulContainer isCollapsed={isCollapsed}>
        <ScMobileNavbarWrapper>
          <ScMobilefFoatingNavbar>
            <Navbar />
          </ScMobilefFoatingNavbar>
        </ScMobileNavbarWrapper>
        <PageContainer isCollapsed={isCollapsed}>{children}</PageContainer>
      </ScDefaulContainer>
      <Alert />
      <Modal />
    </ScPageWrapper>
  );
};
