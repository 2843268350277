import styled from 'styled-components';
import { media } from 'styles/sizes';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
  height: calc(100vh - 180px);
  width: 452px;
  margin: 50px auto;

  @media (${media.mobile}) {
    height: calc(100vh - 450px);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 50px;

  @media (${media.mobile}) {
    margin-top: 0;
  }
`;

export const TitleWrapper = styled.div`
  min-width: 100%;
`;

export const Title = styled.div`
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-align: center;
`;

export const SubTitle = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  text-align: center;
`;

export const NavItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export const LogoutButtonWrapper = styled.div`
  min-width: 100%;

  div {
    margin: 0 auto;
  }
`;
