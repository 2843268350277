import React, { FC, ReactElement, Suspense } from 'react';
import { View } from 'react-navi';
import { AuthProvider } from 'context/auth';
import { AlertProvider } from 'context/alert';
import { FilterProvider } from 'context/filter';
import { ModalProvider } from 'context/modal';
import CombineProviders from 'helpers/combineProviders';
import { Layout } from 'modules/routes/Layout';
import GlobalStyle from './styles/global';
import { NavbarCollapseProvider } from 'context/navbarCollapse';
import { BrowserRouter as Router } from 'react-router-dom';

const providers = [
  AuthProvider,
  AlertProvider,
  FilterProvider,
  ModalProvider,
  NavbarCollapseProvider,
];

const App: FC = (): ReactElement => {
  return (
    <Router>
      <CombineProviders components={[...providers]}>
        <GlobalStyle />
        <Layout>
          <Suspense fallback={null}>
            <View />
          </Suspense>
        </Layout>
      </CombineProviders>
    </Router>
  );
};

export default App;
