import { createSlice } from '@reduxjs/toolkit';
import { productCategoriesApi } from '../services/productCategories';
import { TFullProductCategories } from '../../models/productGuide';
import { RootStore } from '../types';

interface ProductCategoriesState {
  categories: TFullProductCategories[];
}

const initialState: ProductCategoriesState = {
  categories: [],
};
const { getProductCategories } = productCategoriesApi.endpoints;

const slice = createSlice({
  name: 'productCategories',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder.addMatcher(
      getProductCategories.matchFulfilled,
      (state: ProductCategoriesState, { payload }) => {
        state.categories = payload;
      },
    ),
});
export const selectProductCategories = (
  state: RootStore,
): TFullProductCategories[] => state.productCategories.categories;

export const {
  reducer: productCategoriesReducer,
  actions: productCategoriesActions,
} = slice;
