import React, { FC, ReactNode } from 'react';
import { Logo } from 'components/Logo';
import { Alert } from 'components/Alert';

import { ScAuthPage } from 'styles';

import authImg from 'assets/Auth/main.jpg';

export const AuthLayout: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <>
      <ScAuthPage>
        <div className="authColumn">
          <Logo />
          <div className="container">{children}</div>
        </div>
        <div className="authColumn">
          <img src={authImg} className={'main-img'} alt={'Welcome!'} />
        </div>
      </ScAuthPage>
      <Alert />
    </>
  );
};
