import React, { FC, useContext } from 'react';
import { AuthContext } from 'context/auth';
import auth from '../../api/services/auth.service';
import { INavItems, navItems } from 'mocks/navbar';
import { ReactComponent as Logout } from 'assets/Icons/logout.svg';
import { useNavigation } from 'react-navi';
import { MainPageLink } from 'components/MainPageLink';
import {
  Content,
  LogoutButtonWrapper,
  NavItemsWrapper,
  SubTitle,
  Title,
  TitleWrapper,
  Wrapper,
} from './styles';

export const MainPage: FC = () => {
  const { isAuthorized, setAuthorized } = useContext(AuthContext);
  const { navigate } = useNavigation();

  const handleLogout = async () => {
    await auth.logout().then(() => setAuthorized());
  };

  const handleOpen = (e: React.MouseEvent<HTMLElement>, item: INavItems) => {
    e.preventDefault();
    if (item?.children) {
      const link = item.children[0].linkTo;
      return navigate(link as string);
    }
    if (item?.linkTo && item?.name == 'Примечание') {
      const link = item.linkTo;
      return navigate(link as string);
    }
  };

  const firtsColumn = navItems.filter((item, index) => (index + 1) % 2 !== 0);
  const secondColumn = navItems.filter((item, index) => (index + 1) % 2 === 0);

  return (
    <Wrapper>
      <Content>
        <TitleWrapper>
          <Title>Меню</Title>
          <SubTitle>Выберете нужный вам раздел</SubTitle>
        </TitleWrapper>
        <NavItemsWrapper>
          <div>
            {firtsColumn.map((item, index) => (
              <MainPageLink
                name={item.name}
                icon={item.icon}
                hoveredIcon={item.hoveredIcon}
                key={`${index}.${item.name}`}
                onClick={e => handleOpen(e, item)}
              />
            ))}
          </div>
          <div>
            {secondColumn.map((item, index) => (
              <MainPageLink
                name={item.name}
                icon={item.icon}
                hoveredIcon={item.hoveredIcon}
                key={`${index}.${item.name}`}
                onClick={e => handleOpen(e, item)}
              />
            ))}
          </div>
        </NavItemsWrapper>
      </Content>
      {isAuthorized && (
        <LogoutButtonWrapper>
          <MainPageLink
            name={'Выйти из аккаунта'}
            icon={<Logout />}
            onClick={handleLogout}
          />
        </LogoutButtonWrapper>
      )}
    </Wrapper>
  );
};
