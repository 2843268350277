import axios from 'axios';
import { TDdopProduct } from 'models/dops';

export const dopsGet = async (name?: string): Promise<TDdopProduct[]> => {
  const params = name ? { params: { name } } : {};
  return await axios
    .get(`/api/admin/products_dop_get/`, params)
    .then(res => {
      const data: TDdopProduct[] = res.data.data.products_dop;
      return data.map(el => ({
        ...el,
        id: Number(el.id),
      }));
    })
    .catch(e => {
      return e;
    });
};
