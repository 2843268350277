import React, { FC, ReactNode } from 'react';
import { Alert } from 'components/Alert';
import { ScMainPage } from 'styles';
import mainPageImg from 'assets/images/main-page.png';
import { Logo } from 'components/Logo';

export const MainPageLayout: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <>
      <ScMainPage>
        <div className="mainColumn">
          <Logo />
          <div className="container">{children}</div>
        </div>
        <div className="mainColumn">
          <img src={mainPageImg} className={'main-img'} alt={'Welcome!'} />
        </div>
      </ScMainPage>
      <Alert />
    </>
  );
};
