import { createSlice } from '@reduxjs/toolkit';
import { RootStore } from '../types';
import { TDdopProduct } from '../../models/dops';
import { analysesApi } from '../services/analyses';
import { TAnalysesItem } from '../../models/analyses';

interface AnalysesState {
  items: TAnalysesItem[];
}

const initialState: AnalysesState = {
  items: [],
};
const { getAnalyses } = analysesApi.endpoints;

const slice = createSlice({
  name: 'analyses',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder.addMatcher(
      getAnalyses.matchFulfilled,
      (state: AnalysesState, { payload }) => {
        state.items = payload.data;
      },
    ),
});
export const selectAnalyses = (state: RootStore): TDdopProduct[] =>
  state.analyses.items;

export const { reducer: analysesReducer, actions: analysesActions } = slice;
