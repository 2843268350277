import api from './index';
import { ResType, TGetProductsParams } from '../../models/productGuide';
import service from '../../api/services/product.service';
import buildFormDataQuery from '../utils/buildFormDataQuery';
import buildFormDataQueryArr from 'store/utils/buildFormDataQueryArr';

export const productsApi = api.injectEndpoints({
  endpoints: builder => ({
    getProducts: builder.query<ResType, TGetProductsParams>({
      query: params => ({
        url: '/products_get/',
        params: service.getParams(params),
      }),
      transformResponse: (res): ResType => res.data,
      providesTags: ['Products'],
    }),
    addProduct: builder.mutation({
      query: buildFormDataQuery('/products_add/'),
      invalidatesTags: ['Products'],
    }),
    restoreProduct: builder.mutation({
      query: buildFormDataQueryArr('/products_edit/'),
      invalidatesTags: ['Products'],
    }),
    deleteProduct: builder.mutation<any, any>({
      query: buildFormDataQueryArr('/products_del/'),
      invalidatesTags: ['Products'],
    }),
  }),
});

export const {
  useGetProductsQuery,
  useLazyGetProductsQuery,
  useAddProductMutation,
  useRestoreProductMutation,
  useDeleteProductMutation,
} = productsApi;
