import { createSlice } from '@reduxjs/toolkit';
import { RootStore } from '../types';
import { waistHipRatioApi } from 'store/services/waistHipRatio';

interface WaistHipRatioState {
  items: any[];
}

const initialState: WaistHipRatioState = {
  items: [],
};
const { getWaistHipRatio } = waistHipRatioApi.endpoints;

const slice = createSlice({
  name: 'waistHipRatio',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder.addMatcher(
      getWaistHipRatio.matchFulfilled,
      (state: any, { payload }) => {
        state.items = payload;
      },
    ),
});

export const selectWaistHipRatio = (state: RootStore): any[] =>
  state.waistHipRatio.items;

export const { reducer: waistHipRatioReducer, actions: waistHipRatioActions } =
  slice;
