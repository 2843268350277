import React from 'react';
import styled from 'styled-components';
import { TooltipArrow, TooltipText, TooltipWrapper } from './styles';

const Tooltip = styled(({ text, children, className }) => (
  <TooltipWrapper className={className}>
    {children}
    <TooltipText>
      <TooltipArrow />
      {text}
    </TooltipText>
  </TooltipWrapper>
))`
  &:hover ${TooltipText} {
    visibility: visible;
    opacity: 1;
  }
`;

export default Tooltip;
