import api from './index';
import buildFormDataQuery from '../utils/buildFormDataQuery';
import { TResult } from '../../models/all';
import {
  TAddTechCard,
  TechCardsItem,
  TechCardsItemGetParams,
} from '../../models/techCards';
import buildFormDataQueryArr from 'store/utils/buildFormDataQueryArr';

type TGetTechCardsRes = {
  total: number;
  items: Array<TechCardsItem>;
};

export const techCardsApi = api.injectEndpoints({
  endpoints: builder => ({
    getTechCards: builder.query<TGetTechCardsRes, TechCardsItemGetParams>({
      query: buildFormDataQuery('/menus_get/'),
      transformResponse: res => ({
        total: res.data.total,
        items: res.data.menus,
      }),
      providesTags: ['TechCards'],
    }),

    getTechCardItem: builder.query<any, any>({
      query: params => ({ url: '/menus_get/', params }),
      transformResponse: res => res.data.menus,
      providesTags: ['TechCards'],
    }),

    addTechCard: builder.mutation<TResult<{ menus: number }>, TAddTechCard>({
      query: buildFormDataQuery('/menus_add/'),
      transformResponse: res => res.data,
      invalidatesTags: ['TechCards'],
    }),

    editTechCard: builder.mutation<
      TResult<{ menus: number }>,
      { id_item: number } & Partial<TechCardsItem>
    >({
      query: buildFormDataQuery('/menus_edit/'),
      transformResponse: res => {
        return res.data;
      },
      invalidatesTags: ['TechCards'],
    }),

    deleteTechCards: builder.mutation<any, any>({
      query: buildFormDataQueryArr('/menus_del/'),
      invalidatesTags: ['TechCards'],
    }),
  }),
});

export const {
  useGetTechCardsQuery,
  useLazyGetTechCardsQuery,
  useGetTechCardItemQuery,
  useAddTechCardMutation,
  useEditTechCardMutation,
  useDeleteTechCardsMutation,
} = techCardsApi;
