import { createSlice } from '@reduxjs/toolkit';
import { RootStore } from '../types';
import { dopProductsApi } from '../services/dops';
import { TDdopProduct } from '../../models/dops';

interface DopProductsState {
  items: TDdopProduct[];
}

const initialState: DopProductsState = {
  items: [],
};
const { getDopProducts } = dopProductsApi.endpoints;

const slice = createSlice({
  name: 'dopProducts',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder.addMatcher(
      getDopProducts.matchFulfilled,
      (state: DopProductsState, { payload }) => {
        state.items = payload;
      },
    ),
});
export const selectDopProducts = (state: RootStore): TDdopProduct[] =>
  state.dopProducts.items;

export const { reducer: dopProductsReducer, actions: dopProductsActions } =
  slice;
