export default (
  obj1: Record<string, any>,
  obj2: Record<string, any>,
): boolean => {
  const obj1Entries = Object.entries(obj1);
  const obj2Entries = Object.entries(obj2);

  return (
    obj1Entries.length === obj2Entries.length &&
    obj1Entries.every(([key, value]) => obj2[key] === value)
  );
};
