import api from '../index';
import { TFeedingCountItem } from '../../../models/feeding';
import buildFormDataQuery from '../../utils/buildFormDataQuery';
import { TResult } from '../../../models/all';

export const feedingCountApi = api.injectEndpoints({
  endpoints: builder => ({
    // get all density
    getFeedingCount: builder.query<Array<TFeedingCountItem>, void>({
      query: () => '/sprav_eat_count_get/',
      transformResponse: res => res.data.sprav_eat_count,
      providesTags: ['FeedingCount'],
    }),

    // add new density
    addFeedingCount: builder.mutation<
      TResult<{ sprav_eat_count: number }>,
      { name: string; col: string }
    >({
      query: buildFormDataQuery('/sprav_eat_count_add/'),
      transformResponse: res => res.data,
      invalidatesTags: ['FeedingCount'],
    }),

    // edit density
    editFeedingCount: builder.mutation<
      TResult<{ sprav_eat_count: unknown }>,
      { id_item: string; name?: string; col?: string }
    >({
      query: buildFormDataQuery('/sprav_eat_count_edit/'),
      transformResponse: res => res.data,
      invalidatesTags: ['FeedingCount'],
    }),

    // delete density
    deleteFeedingCount: builder.mutation<
      TResult<{ sprav_eat_count: number }>,
      { id_item: string | number }
    >({
      query: buildFormDataQuery('/sprav_eat_count_del/'),
      transformResponse: res => res.data,
      invalidatesTags: ['FeedingCount'],
    }),
  }),
});

export const {
  useGetFeedingCountQuery,
  useAddFeedingCountMutation,
  useEditFeedingCountMutation,
  useDeleteFeedingCountMutation,
} = feedingCountApi;
