import React, { useState, FC, ReactNode, useMemo, createContext } from 'react';
import { initFunc } from 'helpers/initFunction';
import { ModalContextProps, ModalParams } from 'models/modal';

export const ModalContext = createContext<ModalContextProps>({
  setModal: initFunc,
  closeModal: initFunc,
  setModalParams: initFunc,
  params: {},
  isOpen: false,
  modal: <React.Fragment />,
  modalWidth: 610,
  setModalWidth: initFunc,
});

export const ModalProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modalWidth, setModalWidth] = useState<number>(610);
  const [modal, setModalCildren] = useState<ReactNode | JSX.Element>(
    <React.Fragment />,
  );
  const [params, setParams] = useState<ModalParams>({});

  const closeModal = () => {
    setIsOpen(false);
  };

  const setModalParams = (obj: ModalParams) => setParams(obj);

  const setModal = (children: ReactNode | JSX.Element) => {
    setModalCildren(children);
    setIsOpen(true);
  };

  const value = useMemo(() => {
    return {
      setModal,
      isOpen,
      closeModal,
      modal,
      params,
      setModalParams,
      modalWidth,
      setModalWidth,
    };
  }, [setModal, isOpen, closeModal, modal, params, setModalParams]);

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};
