import React, { FC } from 'react';
import { ReactComponent as LogoImg } from 'assets/logo.svg';
import { ReactComponent as CollapsedLogoImg } from 'assets/collapsed-logo.svg';
import { Routes } from 'constants/routes';

import { ScLogo } from './styled';

export interface ILogoProps {
  isCollapsed?: boolean;
}

export const Logo: FC<ILogoProps> = ({ isCollapsed = false }) => {
  return (
    <ScLogo href={Routes.Default}>
      {isCollapsed ? <CollapsedLogoImg className="collapsed" /> : <LogoImg />}
    </ScLogo>
  );
};
